<template>
<!--<div >-->
    <div class="modal extend-dialog centered-modal modal-blur fade" tabindex="-1" role="dialog" aria-hidden="true" v-blockui="locked" id="extend-dialog">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document" style="max-width: 500px !important;">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <template v-if="action == 'edit'">
                            <b class="fa fa-warning"></b> Editar reserva continuada {{ reference }}
                        </template>
                        <template v-else>
                            <b class="fa fa-warning"></b> Reserva continuada la reserva {{ reference }}
                        </template>
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"/><line x1="18" y1="6" x2="6" y2="18" /><line x1="6" y1="6" x2="18" y2="18" /></svg>
                    </button>
<!--                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" @click="" title="Cerrar">&times;</button>-->
                </div>
                <div class="modal-body">
                    <!-- Formulario -->
                    <form class="extend-form">
                        <div class="row">
                            <div class="col-md-6 col-sm-6 col-xs-12">
                                <div class="form-group">
                                    <label class="required">Desde </label>
                                    <span disabled="true" class="form-control">{{ init_date }}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-12">
                                <div class="form-group">
                                    <label class="required">Hasta </label>
                                    <datetimepicker :disabled="locked"  :rules="rules" placeholder="Fecha Entrega" v-model="to_date" format="DD/MM/YY" name="to_date"/>
                                </div>
                            </div>
                        </div>
                    </form>
                    <!--Errores -->
                    <div class="errors" v-show="errors.length > 0">
                        <ul class="bs-callout-danger">
                            <button type="button" class="close" @click="errors = []">&times;</button>
                            <li v-for="error in errors">{{ error }}</li>
                        </ul>
                    </div>
                </div>
                <!--Botones de accion -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-dark btn-close" data-dismiss="modal" @click=""> Cerrar</button>
                    <button type="button" class="btn btn-danger btn-ok" @click="extend"> Aceptar</button>
                </div>

            </div>
        </div>
    </div>
<!--</div>-->
</template>

<script>
// Vue.component('extend-dialog', {
// template: '#extend-dialog-template',
export default {
    props: ['reservation', 'action', 'resultUrls'],
    data: function() {
        return {
            obj: null,
            locked: false,
            extendReservationUrl: this.resultUrls.extend,
            editExtensionUrl: this.resultUrls.editextend,
            res_id: null,
            reference:  null,
            init_date: null,
            rules: {},
            to_date: null,
            errors: []
        };
    },
    watch: {
        //si se actualiza valor descargar reglas y mostrar modal para prorrogar reserva
        reservation: function(reservation) {
            var vm = this;
            if (vm.isPresent(reservation) && reservation.id != vm.res_id) {
                vm.res_id = reservation.id;
                if(vm.action == 'edit') {
                    vm.init_date = reservation.from_date;
                } else {
                    vm.init_date = reservation.to_date;
                }
                vm.reference = reservation.reference;
                vm.rules = {};
                vm.to_date = null;
                vm.errors = [];
                vm.locked = true;
                //descargar reglas para extender la reserva
                vm.extend('init');
            }
        }
    },
    methods: {
        //prorrogar la reserva
        extend: function(action) {
            var vm = this;
            if (action != 'init' && vm.locked) {
                return;
            }
            var url = vm.action == 'edit' ? vm.editExtensionUrl : vm.extendReservationUrl;
            var data = {id: vm.res_id, to_date: vm.to_date};
            vm.locked = true;
            vm.httpPost(url, data).done(function(response){
                if (action == 'init') {
                    if (vm.isPresent(response.errors) && response.errors.length > 0) {
                        vm.$emit('failed', response.errors);
                        return;
                    }
                    vm.obj.modal('show');
                }
                if (vm.isPresent(response.errors)) {
                    vm.errors = response.errors;
                }
                if (vm.isPresent(response.rules)) {
                    vm.rules = response.rules;
                }
                //Si reserva fue prorrogada entonces emitir evento para mostrar voucher
                if (vm.isPresent(response.voucher)) {
                    vm.$emit('confirmed', response.voucher);
                    vm.obj.modal('hide');
                }
            }).fail(function(){
            vm.errors = ['Ha ocurrido un error al intentar acceder al servidor.'];
            }).always(function() {
                vm.$nextTick(function () {
                    vm.locked = false;
                });
            });
        },
        //Funcion ajax, realiza un consulta via post de forma asincrona y retorna un objeto de tipo promise
        httpPost: function(url, data) {
            return $.ajax({
                type: 'POST',
                url: url,
                data: data
            });
        }
    },
    //Constructor se ejecuta al cargar la pagina
    mounted: function() {
        var vm = this;
        window.$(vm.$el).modal({show: false});
        vm.obj = window.$(vm.$el);
        //Limpiar res_id al cerrar el modal
        vm.obj.on('hidden.bs.modal', function(){
            vm.res_id = null;
            vm.reference = null;
            vm.$emit('closed');
        });
    }
}
</script>


<style type="text/css">
    .extend-dialog.centered-modal.in { display: flex !important; }
    .extend-dialog.centered-modal .modal-dialog { margin: auto;}
    .extend-dialog .modal-header { padding: 10px 15px; }
    .extend-dialog .modal-title { font-weight: bold;font-size: 15px; }
    .extend-dialog .modal-title .fa { font-size: 18px; }
    .extend-dialog .modal-title .fa-warning { color: orange; }
    .extend-dialog .modal-body, .extend-dialog .modal-footer { padding: 10px; }
    .extend-dialog .modal-footer .btn { width: 46%; margin-bottom: 0px; }
    #reservation-list .extend-dialog .errors .bs-callout-danger {
        margin: 0;
        padding: 15px 15px 15px 15px;
        border-left: 5px solid #dFb5b4;
        background-color: #fcf2f2;
        list-style: none;
    }
</style>
<template>
  <div class="row">
    <div class="vld-parent">
      <loading :active.sync="isLoading"
               :can-cancel="false"
               :is-full-page="true"></loading>
    </div>
      <div id="currency-list">
        <div class="card-header">
          <div class="col">
            <h3 class="card-title">{{results.table_name}}</h3>
          </div>
          <div class="col">
            <div class="action-btn ml-auto mr-3">
              <div class="dropdown export-btns"  v-if="results.infos" >
                <button class="btn btn-secondary  btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Exportar resultados de búsqueda">
                  <i class="fas fa-download"></i>&nbsp;Exportar
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a :href="results.urls[0]['export']+'?format=pdf'" target="_blank" class="dropdown-item" title="Descargar archivo con formato .pdf">
                    <i class="fas fa-file-pdf export-icon"></i>&nbsp; Exportar como PDF
                  </a>
                  <a :href="results.urls[0]['export']+'?format=xlsx'" target="_blank" class="dropdown-item" title="Descargar archivo con formato .xlsx">
                    <i class="fas fa-file-excel"></i>&nbsp; Exportar como EXCEL
                  </a>
                  <a  :href="results.urls[0]['export']+'?format=csv'" target="_blank" class="dropdown-item" title="Descargar archivo con formato .csv">
                    <i class="fas fa-file-csv"></i>&nbsp; Exportar como CSV
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Cabecera -->
        <div class="card-body border-bottom py-3">
          <!--Listado de errores-->
          <div class="alert alert-danger alert-dismissible fade show" role="alert" v-show="results.errors.length > 0">
            <ul class="bs-callout-danger">
              <li v-for="error in results.errors">{{ error }}</li>
            </ul>
            <button type="button" class="close" @click="results.errors = []">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <form @submit.prevent class="mb-3">
            <div class="d-flex">
              <div class="text-muted">
                Mostrando
                <div class="mx-2 d-inline-block">
                  <select v-model="results.count_by_page"  @change="search('results')" name="count_by_page" class="form-select count_by_page">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                elementos
              </div>
              <div class="ml-auto text-muted">
                <div class="ml-2 d-inline-block search-act">
                  <input placeholder="Buscar..." v-model="results.search"  @keyup.enter="search('results')" class="form-control search-input" type="text" name="search"/>
                </div>
              </div>
            </div>
          </form>
          <!--Tabla de listado de Monedas-->
          <div class="table-responsive">
            <table class="table table-striped table-hover table-condensed table-vcenter">
              <thead class="thead-light">
              <tr>
                <th v-bind:class='{"money": typeof results.class !== "undefined" && results.class[index] === "money" }' v-for="(header, index) in results.headers"> {{header}}</th>
                <th class="text-right mr-5 pr-5">ACCIONES</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="results.infos.length == 0">
                <td :colspan="results.headers.length + 3 " >No existen elementos</td>
              </tr>
              <template v-else v-for="(obj, index) in results.infos" >
                <tr :id="obj.id" :key="obj.id">
                  <td v-bind:class='{"show-details": obj.entity_details, "money": typeof results.class !== "undefined" && results.class[index] === "money" }' @click="obj.entity_details ? showDetails(obj) : '' " v-for="(row, index) in results.rows" >
                    <span v-html="cleanMessage(obj[row])"></span>
                    </td>
                    <!--<td class="show-details" @click="showDetails(obj)">{{ obj.sequence }}</td>-->
                  <td>
                    <div class="btn-list flex-nowrap right-content">
                      <a :href="obj.view" class="btn btn-outline-secondary " title="Ver" v-show="obj.view"><i class="far fa-file-alt"></i></a>
                      <a :href="obj.edit" class="btn btn-outline-warning btn-icon" title="Editar" v-show="obj.edit"><i class="fas fa-edit"></i></a>
                      <span class="action-btns" v-for="(accion, index) in obj.get_actions" v-if="obj.get_actions" >
                        <div :title="accion[3]">
                          <a :href="accion[1]" class="btn btn-outline-info btn-icon mr-1" data-turbolinks="false" v-bind:class="{'disabled': accion[4] }" :target="setTarget(accion)" :title="accion[3]" v-show="accion[0]"><i :class="accion[2]"></i> </a>
                        </div>
                      </span>
                      <span class="btn btn-outline-danger btn-icon" @click="removeObj(obj, index, results.total_count)"  title="Eliminar" v-bind:class="{'disabled': obj.disabled }" v-show="obj.delete"><i class="fas fa-trash-alt"></i></span>
                        <!--                      <div class="dropdown">-->
                        <!--                        <button class="btn btn-white dropdown-toggle align-text-top" data-boundary="viewport" data-toggle="dropdown">Actions</button>-->
                        <!--                        <div class="dropdown-menu dropdown-menu-right">-->
                        <!--                          <a class="dropdown-item" href="#">-->
                        <!--                            Action-->
                        <!--                          </a>-->
                        <!--                          <a class="dropdown-item" href="#">-->
                        <!--                            Another action-->
                        <!--                          </a>-->
                        <!--                        </div>-->
                        <!--                      </div>-->
                    </div>
                  </td>


                    <!--<td class="action-btns">-->
                    <!--<span class="btn btn-warning btn-xs" title="Activar/Desactivar" @click="ACTobj(obj, index, results.total_count)"><i class="fas fa-edit"></i></span>-->
                    <!--</td>-->

                </tr>
              </template>
              </tbody>
            </table>
          </div>
          <form @submit.prevent class="tablepager" v-if="showTablePager">
            <div class="card-footer d-flex align-items-center">
              <div class="col">
                Página {{ results['page'] }} de {{ total_pages() }} (Total de elementos: {{ results.total_count }})
              </div>
              <div class="col">
                <div class="pagination m-0">
                  <div class="  ml-auto">
                    <span class="btn btn-ghost-secondary" v-bind:class="{ disabled: results['page'] <= 1 }" title="Primera página" :disabled="results['page'] <= 1" @click="pagerArrows('first', results)"><i class="fas fa-step-backward"></i></span>
                    <span class="btn btn-ghost-secondary" v-bind:class="{ disabled: results['page'] <= 1 }" title="Página anterior" :disabled="results['page'] <= 1" @click="pagerArrows('prev', results)"><i class="fas fa-chevron-left"></i></span>
                  </div>
                  <input type="text" class="form-control-rounded pagination-input" v-model="results.page" title="Página actual"  @keyup.enter="search" @input="resultsPage">
                  <div class="input-group-btn">
                    <span class="btn btn-ghost-secondary" v-bind:class="{ disabled: results['page'] >= total_pages() }" title="Página siguiente" :disabled=" results['page'] >= total_pages()" @click="pagerArrows('next', results)"><i class="fas fa-chevron-right"></i></span>
                    <span class="btn btn-ghost-secondary" v-bind:class="{ disabled: results['page'] >= total_pages() }" title="Última página" :disabled="results['page'] >= total_pages()" @click="pagerArrows('last', results)"><i class="fas fa-step-forward"></i></span>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

</template>

<script>
  export default {
    props: ["results"],
    methods: {
      cleanMessage(message) {
        return this.$sanitize(message);
      },
      setTarget(obj){
        if(obj.length === 6 ){
          return (obj[5] === true ? "_blank" : "_self");
        }
      },
      errorMsm(response){
        let message = "";
        if (response.status === 0) {
          message = 'SIN CONEXIÓN: Verifique su conexión de red.';
        } else if(response.status == 400){
          message = 'EXISTEN PROBLEMAS EN EL REQUEST: Existen problemas con los datos enviados, intente nuevamente';
        } else if(response.status == 401){
          message = 'AUNTENTICACIÓN FALLIDA: Autentiquece nuevamente.';
        }else if (response.status == 403) {
          message == "AUNTENTICACIÓN FALLIDA: Autentiquece nuevamente.";
        } else if (response.status == 404) {
          message = 'PÁGINA NO ENCONTRADA: La página a la que está tratando de acceder nos se encontró, intente nuevamente';
        } else if (response.status == 500) {
          message = 'ERROR: Ha ocurrido un error que fue notificado al equipo de soporte, intene nuevamente en unos minutos.';
        } else if (response.status == 503){
          message = "Service unavailable.";
        } else if (response.statusText === 'parsererror') {
          message = 'ERROR: Ha ocurrido un error que fue notificado al equipo de soporte, intene nuevamente en unos minutos.';
        } else if (response.statusText === 'timeout') {
          message = 'TIME  OUT: Tiempo de espera superado, verifique su conexión de red.';
        } else if (response.statusText === 'abort') {
          message = 'REQUEST ABORTADO: La operación ha sido abortada';
        } else {
          message = 'ERROR: Ha ocurrido un error que fue notificado al equipo de soporte, intene nuevamente en unos minutos.';
        }
        return message;
      },
      removeObj(obj, index, total) {
        const login_url = this.$props.results.urls[0]['login'];
        const url = obj.delete;
        this.$dialog.confirm(obj.confirmDialog, {
          okText:'Aceptar',
          cancelText:'Cerrar',
          loader: true,
          // And a dialog object will be passed to the then() callback
        })
          .then((dialog) => {
            // Triggered when proceed button is clicked
            this.isLoading = true;
            this.$http.delete( url, {params: {id: obj.id, total: total }}).then(response => {

              if(response.body.results != null && response.body.results.errors != null){
                this.$props.results.errors = [response.body.results.errors];
                this.isLoading = false;
                return
              }
              if (response.body.results == null && response.body.substring(0, 10) == 'Turbolinks'){
                Turbolinks.clearCache();
                Turbolinks.visit(login_url, {"action":"replace"});
              }
              obj._destroy = true;
              this.results.infos.splice(index, 1);
              this.$delete;
              this.isLoading = false;
              this.$props.results.total_count = this.$props.results.total_count - 1 ;
            }, response=> {
              let message = this.errorMsm(response);
              this.$props.results.errors = [message];
              this.isLoading = false;
            }
            );
            // dialog.loading(false) // stops the proceed button's loader
            // dialog.loading(true) // starts the proceed button's loader again
            dialog.close(); // stops the loader and close the dialog
          })
          .catch(() => {
            this.isLoading = false;
            // Triggered when cancel button is clicked
          });
      },
      search(){
        let vm = this;
        let force = force || false;
        if (vm.locked && !force) {
          return;
        }
        const login_url = this.$props.results.urls[0]['login'];
        const search_url = this.$props.results.urls[0]['search'];
        let data = $.extend(true, {}, vm.results);
        //EN GET los parámetros se pasan {params: {data: data}} en post {data: data}
        this.isLoading = true;
        this.$http.post(search_url, {data: data}).then(response => {
                  if(vm.isBlank(response)) {
                    this.$props.results.errors = ['No fue posible recuperar información desde el servidor'];
                    this.isLoading = false;
                    return;
                  }
                  if (response.body.results == null && response.body.substring(0, 10) == 'Turbolinks'){
                    Turbolinks.clearCache();
                    this.isLoading = false;
                    Turbolinks.visit(login_url, {"action":"replace"});
                  }
                  this.$props['results'].page = response.body.results.page;
                  this.$props['results'].count_by_page = response.body.results.count_by_page;
                  this.$props['results'].total_count =  response.body.results.total_count;
                  this.$emit("update-lists", response.body.results);
                  this.isLoading = false;
                }, response=> {
                  let message = this.errorMsm(response);
                  this.$props.results.errors = [message];
                  this.isLoading = false;
                });
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      },
      showTablePager() {
        let vm = this;
        return vm.isPresent(vm.results) &&
                vm.isPresent(vm.total_pages) &&
                vm.total_pages > 1
                ;
      },
      pagerArrows(action, results) {
        let vm = this;
        switch(action) {
          case 'first':
            results.page = 1;
            break;
          case 'prev':
            results.page--;
            break;
          case 'next':
            results.page++;
            break;
          case 'last':
            results.page = vm.total_pages();
            break;
        }
        this.search();
      },
      resultsPage(e){
        let vm = this;
        let results = this.$props['results'];
        let value = e.target.value.replace(/[^0-9]*/g, '');
        if (!(value > 0)){
          results.page = '1';
          this.$nextTick(function () {
            $('.tablepager input').select();
          })
        } else if (vm.isPresent(vm.results) && vm.isPresent(vm.total_pages()) && value > parseInt(vm.total_pages())) {
          vm.results.page = vm.total_pages();
        } else if(value !=results['page']) {
          results.page = value;
        }
      },
      total_pages() {
        let vm = this;
        let results = this.$props['results'];
        let result = 1;
        if (vm.isPresent(results) && vm.isPresent(results.count_by_page) &&
                results.total_count > 0 && results.count_by_page > 0) {
          result = parseInt(results.total_count/results.count_by_page);

          if (results.total_count % results.count_by_page > 0) {
            result += 1;
          }
        }
        return result;
      },
      showDetails(obj) {
        obj.show_details = !obj.show_details;
      }
    },
    data() {
      return {
        isLoading: false,
        fullPage: true,
        componentKey: 0,
      }
    }
  }
</script>

<style scoped>

  p {
    font-size: 2em;
    text-align: center;
  }

  [v-cloak] { display: none; }

  #currency-list .action-btn { text-align: right; }

  #currency-list { margin-top: 20px; }

  #currency-list hr { margin-top: 0px; }

  #currency-list .count_by_page { max-width: 80px; }

  #currency-list .search-act { text-align: right; }

  #currency-list .search-act input {
    max-width: 180px;
    display: inline-block;
  }

  #currency-list table tbody td {
    vertical-align: middle;
  }

  #currency-list .tablepager .input-group .input-group-btn .btn {
    padding: 4px 12px;
    font-size: 10px;
    cursor: pointer;
    margin-right: 0px;
  }

  #currency-list .tablepager .input-group-btn:first-child>.btn,  #currency-list .tablepager .input-group-btn:first-child>.btn-group {
    margin-right: -1px;
  }

  #currency-list .tablepager .input-group-addon:hover { background-color: #ddd; }
  #currency-list .tablepager .input-group-addon:active { background-color: #ccc; }

  #currency-list .tablepager {
    line-height: 24px;
  }

  #currency-list .tablepager .input-group {
    width: 200px;
    float: right;
  }

  #currency-list .tablepager input {
    text-align: center;
    height: 24px;
  }

  #currency-list .errors .bs-callout-danger {
    margin: 0;
    padding: 15px 30px 15px 15px;
    border-left: 5px solid #dFb5b4;
    background-color: #fcf2f2;
    list-style: none;
  }

  #currency-list .voucher-content {
    padding: 15px;
    background-color: white !important;
  }

  #currency-list .show-details {
    cursor: pointer;
  }
  #currency-list .voucher-content b {
    width: 150px;
    display: inline-block;
    text-align: right;
    margin-right: 15px;
  }

  #currency-list .dropdown-menu {
    min-width: 100px;
  }

  #currency-list .dropdown-menu-right.dropdown-menu:after {
    right: 5%;
  }

  #currency-list .recent-work {
    background-color: #ededed;
    font-weight: bold;
  }

  #currency-list td:first-child, #currency-list th:first-child  {
    padding-left: 15px;
  }

  #currency-list .recent-work td:first-child::before {
    content: '\f0da';
    font: normal normal normal 14px/1 FontAwesome;
    margin-left: -10px;
    font-size: 14px;
    padding-right: 5px;
  }

  #currency-list .results .daterange-dot:before {
    content: ':';
    float: left;
    margin-left: -13px;
    font-size: 20px;
    font-weight: bold;
  }

  @media (min-width: 768px) {
    #currency-list .results .daterange-dot {
      margin-top: 22px;
    }
  }


  #currency-list .details .details-data th {
    text-align: right;
    width: 122px;
    vertical-align: top;
    padding: 0 0 10px 0;
    margin: 0 0 10px;
  }

  #currency-list .details .details-data td {
    padding-left: 15px;
    padding-bottom: 10px;
  }

  #currency-list .export-btns {
    margin-bottom: 5px;
    margin-right: 5px;
  }
  #currency-list .export-btns .dropdown-menu { margin-top: 6px; }
  #currency-list .export-btns .dropdown-menu li a {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 10px;
  }
  #currency-list .export-btns .dropdown-menu li a i { margin-right: 9px; font-size: 18px; }
  #currency-list .export-btns .btn .caret { margin-left: 0; }

  #currency-list .export-btns .dropdown-menu {
    left: auto;
  }

  #currency-list .action-btn .btn:last-child {
    margin-right: 0px;
  }

  #currency-list .action-btns .btn[disabled] {
    opacity: .3;
  }

  #currency-list .action-btns .btn {
    /*min-width: 25px;*/
  }

  #currency-list .form-group.action-btn {
    margin-top: 5px;
  }

  #currency-list .diff {
    text-decoration: line-through;
    color: #c70101;
  }

  #currency-list .details td span {
    line-height: inherit;
  }

  #currency-list .details-divider { margin-top: 20px; }

  #currency-list .details-divider .badge {
    float: left;
    position: relative;
    display: inline-block;
    top: -11px;
    min-width: 23px;
    border-radius: 15px;
  }

  .centered-modal.in {
    display: flex !important;
  }
  .centered-modal .modal-dialog {
    margin: auto;
  }
  .btn_agency{
    color: #c70101;
  }
  .dropdown-menu>li>span {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    cursor:pointer;
  }
  .pagination-input{
    text-align: center;
    width: 10%;
  }

  .right-content{
    float: right;
  }

</style>

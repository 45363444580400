<template>
    <div class="card" v-if="showVoucher" id="reservation-voucher">
        <div class="voucher-data">
            <div class="card-header">
              <h3 class="card-title">
                  DATOS DE RESERVA
                  <a class="btn btn-light" title="Descargar voucher" :href="options.voucherUrl + '?id=' + reservation.id" target="_blank" style="margin-left: 15px;"><i class="fas fa-download"></i></a>
              </h3>
              <button class="btn btn-secondary ml-auto" @click="close">Cerrar</button>
            </div>
            <div class="voucher-content card-body" style="margin: 15px;" v-if="isPresent(reservation)">
                <p><b>Referencia: </b>{{ reservation.reference }}</p>
                <p v-show="isPresent(reservation.extended_child)"><b>Prórrogada por: </b>{{ reservation.extended_child }}</p>
                <p v-show="isPresent(reservation.go_on_child)"><b>Continuada por: </b>{{ reservation.go_on_child }}</p>
                <p v-show="isPresent(reservation.extended_parent)"><b>Prórroga de: </b>{{ reservation.extended_parent }}</p>
                <p v-show="isPresent(reservation.go_on_parent)"><b>Continuada de: </b>{{ reservation.go_on_parent }}</p>
                <p><b>Fecha Creada: </b>{{ reservation.created_at }}</p>
                <p><b>Última Modificación: </b>{{ reservation.updated_at }}</p>
                <div class="hr-text hr-text-left text-gray-dark"></div>
                <p><b>Sucursal:</b> {{ reservation.dealer }}</p>
                <p><b>Categoría: </b>{{ reservation.service }}</p>
                <p><b>Vuelo de Arribo: </b>{{ reservation.client_flight }}</p>
                <p><b>Fecha Recogida: </b>{{ reservation.from_date }}</p>
                <p><b>Oficina Recogida: </b>{{ reservation.pick_up_location }}</p>
                <p><b>Fecha Entrega: </b>{{ reservation.to_date }}</p>
                <p><b>Oficina Entrega: </b>{{ reservation.drop_off_location }}</p>
                <p><b>Días: </b>{{ reservation.days }}</p>
                <div class="hr-text hr-text-left text-gray-dark"></div>
                <p><b>Cobro de Renta: </b>{{ reservation.service_price }}</p>
                <p><b>Cobro de Relevo de Responsabilidad: </b>{{ reservation.price_insurance }}</p>
                <p><b>Cobro de Suplemento de Relevo de Responsabilidad: </b>{{ reservation.supplement_insurance }}</p>
                <p><b>Chófer adicional: </b>{{ reservation.additional_drivers }}</p>
                <p><b>Cobro de Servicio de Habilitado: </b>{{ reservation.gas_price }}</p>
                <p><b>Cobro de Drop Off: </b>{{ reservation.price_drop_off }}</p>
                <p><b>Cobro de Suplemento de oficina: </b>{{ reservation.price_hire_point }}</p>
                <p><b>Cobro de Salón VIP: </b>{{ reservation.vip }}</p>
                <div class="hr-text hr-text-left text-gray-dark"></div>
                <p><b>Cliente: </b>{{ reservation.client_first_name }} {{ reservation.client_last_name }}</p>
                <p><b>Correo: </b>{{ reservation.client_email }}</p>
                <p><b>Pasaporte: </b>{{ reservation.client_passport }}</p>
                <p><b>País: </b>{{ reservation.client_country }}</p>
                <p v-show="isPresent(reservation.business_reference2)"><b>Identificador #1: </b>{{ reservation.business_reference2 }}</p>
                <p v-show="isPresent(reservation.business_reference3)"><b>Identificador #2: </b>{{ reservation.business_reference3 }}</p>
                <p style="white-space: pre-line;"><b>Otras Aclaraciones: </b>{{ reservation.client_details }}</p>
                <div class="hr-text hr-text-left text-gray-dark"></div>
                <p><b>Agencia:</b> {{ reservation.agency }}</p>
                <p><b>Contrato: </b>{{ reservation.contract }}</p>
                <hr/>
                <p><b>Atendido por:</b> {{ reservation.user_name }}</p>
                <p><b>Teléfonos:</b> {{ reservation.user_phone }}</p>
                <p><b>Información de Negocio:</b> {{ reservation.user_info }}</p>
                <p class="text-right">
                    <button class="btn btn-secondary" @click="close">Cerrar</button>
                </p>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    // Vue.component('reservation-voucher', {
    //     template: '#reservation-voucher-template',
export default {
    props: ['reservation'],
    data: function () {
        return {
            // <%# Opciones genrales como urls %>
            options: {
                voucherUrl: 'voucher'
            }
        };
    },
    computed: {
        // <%# Define si se debe mostrar el voucher Ej cuando la reserva se confirma %>
        showVoucher: function() {
            return this.isPresent(this.reservation);
        }
    },
    methods: {
        close: function() {
          $('input[name="client_email"]').val("");
          this.$emit('close');
        }
    }
}
</script>

<style type="text/css">
    .voucher-data .voucher-content b {
        width: 150px;
        display: inline-block;
        text-align: right;
        margin-right: 15px;
    }
</style>
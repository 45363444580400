/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//require("@rails/ujs").start()
//require("turbolinks").start()
// import '@fortawesome/fontawesome-free-solid'
//require("imports-loader");

//CSS
import '../stylesheets/application'
import "@fortawesome/fontawesome-free/js/all";
// import '@fortawesome/fontawesome-free/css/all.min.css'
// import '@fortawesome/fontawesome-free/css/v4-shims.min.css'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import 'vue-loading-overlay/dist/vue-loading.css'
import "trix/dist/trix.css";

// require("@rails/ujs").start();
// require("turbolinks").start();
var Trix  = require("trix");
require("@rails/actiontext");
require("@rails/activestorage").start();

require("channels");
// require("jquery-ui-dist/jquery-ui");
// var jQuery = require("jquery");

import jQuery from "jquery";
import $ from 'jquery';
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
global.moment = require('moment');

require('bootstrap/dist/js/bootstrap.min');
require('popper.js/dist/popper.min');
require("controllers");
require('moment/dist/moment');
require('moment/dist/locale/es');
require("select2/dist/css/select2.min.css");
require("select2-bootstrap-theme/dist/select2-bootstrap.min.css");
require('tempusdominus-bootstrap-4/build/js/tempusdominus-bootstrap-4.min');
require('tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.min.css');
require('block-ui/jquery.blockUI');
// require('../../assets/javascripts/dist/libs/spinner.js');
//require("./main");

import Turbolinks from 'turbolinks'
import Vue from 'vue/dist/vue.esm';
import VueResource from 'vue-resource';
import VuejsDialog from "vuejs-dialog"
import VueSanitize from "vue-sanitize"
import Notifications from 'vue-notification'
import Loading from 'vue-loading-overlay';
import noActionTable from './components/noActionTable.vue'
import basicTable from './components/basicTable.vue'
import basicOrderedTable from './components/basicOrderedTable.vue'
import claimTable from './components/claimTable.vue'
import PaymentRequestTable from './components/paymentRequests.vue'
import userTable from "./components/userTable.vue";
import agencyTable from './components/agencyTable.vue'
import CurrencyDetails from './components/adminComponentsDetails/currencyDetails.vue'
import DestinationDetails from './components/adminComponentsDetails/destinationDetails.vue'
import HotelPlansDetails from './components/adminComponentsDetails/hotelPlansDetails.vue'
import PmsConnectionsDetails from './components/adminComponentsDetails/pmsConetionsDetails.vue'
import MerchantTranslateDetails from './components/adminComponentsDetails/merchantTranslateDetails.vue'
import AcquiresDetails from './components/adminComponentsDetails/acquiresDetails.vue'
import NoticeDetails from './components/adminComponentsDetails/noticeDetails.vue'
import ClaimDetails from './components/adminComponentsDetails/claimDetails.vue'
import FaqDetails from './components/adminComponentsDetails/faqDetails.vue'
import extendDialog from './components/agency/extendDialog.vue'
import vueTypeahead from './components/agency/vueTypeahead.vue'
import reservationVoucher from './components/agency/reservationVoucher.vue'
import DateTimePicker from './components/agency/datetimepicker.vue'
import reservationDatePicker from './components/agency/reservationdatepicker.vue'
import Datepicker from 'vuejs-datepicker';
import reservationForm from './components/agency/reservationForm.vue'
import reservationList from './components/agency/reservationList.vue'
import reservationReport from './components/reservationReport.vue'
import reservationListFilterForm from './components/agency/reservationListFilterForm.vue'
import reservationListReservationDetails from './components/agency/reservationListReservationDetails.vue'


Turbolinks.start();
Vue.use(VueResource);
Vue.use(VuejsDialog);
Vue.use(VueSanitize);
Vue.use(Notifications);
document.addEventListener('turbolinks:load', () => {
    Vue.http.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    var table = document.querySelector('#basic-table');
    var table1 = document.querySelector('#basic-table-1');
    var payment_request_table = document.querySelector('#payment_request_table');
    var claim_table = document.querySelector('#claim-table');
    var ordered_table = document.querySelector('#ordered-table');
    var agency_table = document.querySelector('#agency-table');
    if (table != undefined){
        Vue.mixin({
            methods: {
                // Similar a la funcion blank? de rails
                isBlank: function(data) { return (data === undefined || data === null || data === false || (typeof data == 'string' && data.trim() === '')); },

                // Similar a la funcion present? de rails y basicamente !blank?
                isPresent: function(data) { return !this.isBlank(data); }
            }
        });
        Vue.component('currency-details', CurrencyDetails);
        Vue.component('destination-details', DestinationDetails);
        Vue.component('hplans-details', HotelPlansDetails);
        Vue.component('pmscox-details', PmsConnectionsDetails);
        Vue.component('merchantt-details', MerchantTranslateDetails);
        Vue.component('acquires-details', AcquiresDetails);
        Vue.component('notice-details', NoticeDetails);
        Vue.component('faq-details', FaqDetails);
        //Vue.component('confirm-dialog', ConfirmDialog);
        Vue.component('loading', Loading);
        const app = new Vue({
            el: table,
            data:{
                lists: JSON.parse(table.dataset.lists).results,
            },
            template: "<basicTable :results='lists' @update-lists ='update' />",
            components: {
                basicTable
            },
            methods: {
                update(list) {
                    this.lists = list;
                }
            }
        });
    }
    if (table1 != undefined){
        Vue.mixin({
            methods: {
                // Similar a la funcion blank? de rails
                isBlank: function(data) { return (data === undefined || data === null || data === false || (typeof data == 'string' && data.trim() === '')); },

                // Similar a la funcion present? de rails y basicamente !blank?
                isPresent: function(data) { return !this.isBlank(data); }
            }
        });
        Vue.component('currency-details', CurrencyDetails);
        Vue.component('destination-details', DestinationDetails);
        Vue.component('hplans-details', HotelPlansDetails);
        Vue.component('pmscox-details', PmsConnectionsDetails);
        Vue.component('merchantt-details', MerchantTranslateDetails);
        Vue.component('acquires-details', AcquiresDetails);
        Vue.component('notice-details', NoticeDetails);
        Vue.component('faq-details', FaqDetails);
        //Vue.component('confirm-dialog', ConfirmDialog);
        Vue.component('loading', Loading);
        const app = new Vue({
            el: table1,
            data:{
                lists: JSON.parse(table1.dataset.lists).results,
            },
            template: "<basicTable :results='lists' @update-lists ='update' />",
            components: {
                basicTable
            },
            methods: {
                update(list) {
                    this.lists = list;
                }
            }
        });
    }
    if (payment_request_table != undefined){
        Vue.mixin({
            methods: {
                // Similar a la funcion blank? de rails
                isBlank: function(data) { return (data === undefined || data === null || data === false || (typeof data == 'string' && data.trim() === '')); },
                // Similar a la funcion present? de rails y basicamente !blank?
                isPresent: function(data) { return !this.isBlank(data); }
            }
        });
        Vue.component('loading', Loading);
        const app = new Vue({
            el: payment_request_table,
            data:{
                lists: JSON.parse(payment_request_table.dataset.lists).results,
            },
            template: "<paymentRequestTable :results='lists' @update-lists ='update' />",
            components: {
                PaymentRequestTable
            },
            methods: {
                update(list) {
                    this.lists = list;
                }
            }
        });
    }
    if(claim_table != undefined){
        Vue.mixin({
            methods: {
                // Similar a la funcion blank? de rails
                isBlank: function(data) { return (data === undefined || data === null || data === false || (typeof data == 'string' && data.trim() === '')); },
                // Similar a la funcion present? de rails y basicamente !blank?
                isPresent: function(data) { return !this.isBlank(data); }
            }
        });
        Vue.component('loading', Loading);
        Vue.component('claim-details', ClaimDetails);
        const app = new Vue({
            el: claim_table,
            data:{
                lists: JSON.parse(claim_table.dataset.lists).results,
            },
            template: "<claimTable :results='lists' @update-lists ='update' />",
            components: {
                claimTable
            },
            methods: {
                update(list) {
                    this.lists = list;
                }
            }
        });
    }
    if (ordered_table != undefined){
        Vue.mixin({
            methods: {
                // Similar a la funcion blank? de rails
                isBlank: function(data) { return (data === undefined || data === null || data === false || (typeof data == 'string' && data.trim() === '')); },

                // Similar a la funcion present? de rails y basicamente !blank?
                isPresent: function(data) { return !this.isBlank(data); }
            }
        });
        Vue.component('notice-details', NoticeDetails);
        //Vue.component('confirm-dialog', ConfirmDialog);
        const app = new Vue({
            el: ordered_table,
            data:{
                lists: JSON.parse(ordered_table.dataset.lists).results,
            },
            template: "<basicOrderedTable :results='lists' @update-lists ='update' />",
            components: {
                basicOrderedTable
            },
            methods: {
                update(list) {
                    this.lists = list;
                }
            }
        });
    }
    if (agency_table != undefined){
        Vue.mixin({
            methods: {
                // Similar a la funcion blank? de rails
                isBlank: function(data) { return (data === undefined || data === null || data === false || (typeof data == 'string' && data.trim() === '')); },

                // Similar a la funcion present? de rails y basicamente !blank?
                isPresent: function(data) { return !this.isBlank(data); }
            }
        });
        Vue.component('loading', Loading);
        const app = new Vue({
            el: agency_table,
            data:{
                lists: JSON.parse(agency_table.dataset.lists).results,
            },
            template: "<agencyTable :results='lists' @update-lists ='update' />",
            components: {
                agencyTable
            },
            methods: {
                update(list) {
                    this.lists = list;
                }
            }
        });
    }

});
document.addEventListener('turbolinks:load', () => {
    Vue.http.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    var no_action_table = document.querySelector('#no-action-table');
    if (no_action_table != undefined){
        Vue.mixin({
            methods: {
                // Similar a la funcion blank? de rails
                isBlank: function(data) { return (data === undefined || data === null || data === false || (typeof data == 'string' && data.trim() === '')); },
                // Similar a la funcion present? de rails y basicamente !blank?
                isPresent: function(data) { return !this.isBlank(data); }
            }
        });
        //Vue.component('confirm-dialog', ConfirmDialog);
        Vue.component('loading', Loading);
        const app = new Vue({
            el: no_action_table,
            data:{
                lists: JSON.parse(no_action_table.dataset.lists).results,
            },
            template: "<noActionTable :results='lists' @update-lists ='update' />",
            components: {
                noActionTable
            },
            methods: {
                update(list) {
                    this.lists = list;
                }
            }
        });
    }
});
document.addEventListener('turbolinks:load', () => {
    Vue.http.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    var user_table = document.querySelector('#user-table');
    if (user_table != undefined){
        Vue.mixin({
            methods: {
                // Similar a la funcion blank? de rails
                isBlank: function(data) { return (data === undefined || data === null || data === false || (typeof data == 'string' && data.trim() === '')); },
                // Similar a la funcion present? de rails y basicamente !blank?
                isPresent: function(data) { return !this.isBlank(data); }
            }
        });
        //Vue.component('confirm-dialog', ConfirmDialog);
        Vue.component('loading', Loading);
        const app = new Vue({
            el: user_table,
            data:{
                lists: JSON.parse(user_table.dataset.lists).results,
            },
            template: "<userTable :results='lists' @update-lists ='update' />",
            components: {
                userTable
            },
            methods: {
                update(list) {
                    this.lists = list;
                }
            }
        });
    }
});
//Formulario de reserva de la agencia
document.addEventListener('turbolinks:load', () => {
    // Vue.http.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    var reservation_list_form = document.querySelector('#reservation-page');
    if (reservation_list_form != undefined){
        Vue.mixin({
            methods: {
                // Similar a la funcion blank? de rails
                isBlank: function(data) { return (data === undefined || data === null || data === false || (typeof data == 'string' && data.trim() == '')); },
                // Similar a la funcion present? de rails y basicamente !blank?
                isPresent: function(data) { return !this.isBlank(data); }
            }
        });
        Vue.directive('blockui', function(el, binding){
            if(binding.value !== binding.oldValue) {
                if (binding.value === true) {
                    $(el).block({
                        message: false,
                        overlayCSS:  {
                            opacity:  0.3
                        }
                    });
                }
                else {
                    $(el).unblock();
                }
            }
        });
        Vue.component('extend-dialog', extendDialog);
        Vue.component('select2', {
            template: '<select class="form-control" :name="name"></select>',
            props: ['name', 'disabled', 'options', 'placeholder', 'value'],
            data: function() {
                return {
                    current: this.value
                };
            },
            computed: {
                isDisabled: function() {
                    var vm = this;
                    return vm.disabled !== false;
                }
            },
            watch: {
                value: function(value) {
                    var vm = this;
                    $(vm.$el)
                        .val(value)
                        .trigger('change');
                },
                options: function(options) {
                    var vm = this;
                    $(vm.$el).empty().select2({
                        data: options,
                        placeholder: vm.placeholder,
                        allowClear: vm.placeholder,
                        theme: "bootstrap",
                        language: "es"
                    })
                        .val(vm.value)
                        .trigger("change");
                },
                isDisabled: function() {
                    this.setDisabledStatus();
                }
            },
            methods: {
                setDisabledStatus: function(){
                    var vm = this;
                    $(vm.$el).prop('disabled', vm.isDisabled);
                }
            },
            mounted: function() {
                var vm = this;
                $(vm.$el).select2({
                    data: vm.options,
                    allowClear: vm.placeholder,
                    placeholder: vm.placeholder,
                    theme: "bootstrap",
                    language: "es"
                })
                    .val(vm.value)
                    .trigger('change')
                    .on('change', function (e) {
                        if(vm.current != this.value) {
                            vm.current = this.value;

                            if(!vm.isDisabled) {
                                vm.$emit('input', this.value);
                            }
                        }
                    });

                this.setDisabledStatus();
            },
            destroyed: function() {
                var vm = this;
                $(vm.$el).off().select2('destroy');
            }
        });
        Vue.component('datepicker', Datepicker);
        Vue.component('datetimepicker', DateTimePicker);
        Vue.component('reservationDatePicker', reservationDatePicker);
        Vue.component('autocomplete', vueTypeahead);
        Vue.component('reservation-voucher', reservationVoucher);
        if(JSON.parse(reservation_list_form.dataset.lists).results.cant_reserve === true){
            Vue.component('reservation-form', reservationForm);
        }
        Vue.component('extend-dialog', extendDialog);
        Vue.component('reservation-list-filter-form', reservationListFilterForm);
        Vue.component('reservation-list-reservation-details', reservationListReservationDetails);
        // Vue.component('confirm-dialog', VuejsDialog);
        Vue.component('loading', Loading);
        //Vue.component('reservation-list-template', reservationList);
        var app = new Vue({
            el: '#reservation-page',
            model: {prop: 'filter'},
            data:{
                lists: JSON.parse(reservation_list_form.dataset.lists).results,
            },
            template: "<reservation-list :resultUrls='lists' />",
            components: {
                reservationList
            },
        });

    }
});

//Formulario de reporte de reservas
document.addEventListener('turbolinks:load', () => {
    // Vue.http.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    var reservation_report = document.querySelector('#reservation-report');
    if (reservation_report != undefined){
        Vue.mixin({
            methods: {
                // Similar a la funcion blank? de rails
                isBlank: function(data) { return (data === undefined || data === null || data === false || (typeof data == 'string' && data.trim() == '')); },
                // Similar a la funcion present? de rails y basicamente !blank?
                isPresent: function(data) { return !this.isBlank(data); }
            }
        });
        Vue.directive('blockui', function(el, binding){
            if(binding.value != binding.oldValue) {
                if (binding.value == true) {
                    $(el).block({
                        message: false,
                        overlayCSS:  {
                            opacity:  0.3
                        }
                    });
                }
                else {
                    $(el).unblock();
                }
            }
        });
        Vue.component('extend-dialog', extendDialog);
        Vue.component('select2', {
            template: '<select class="form-control" :name="name"></select>',
            props: ['name', 'disabled', 'options', 'placeholder', 'value'],
            data: function() {
                return {
                    current: this.value
                };
            },
            computed: {
                isDisabled: function() {
                    var vm = this;
                    return vm.disabled !== false;
                }
            },
            watch: {
                value: function(value) {
                    var vm = this;
                    $(vm.$el)
                        .val(value)
                        .trigger('change');
                },
                options: function(options) {
                    var vm = this;
                    $(vm.$el).empty().select2({
                        data: options,
                        placeholder: vm.placeholder,
                        allowClear: vm.placeholder,
                        theme: "bootstrap",
                        language: "es"
                    })
                        .val(vm.value)
                        .trigger("change");
                },
                isDisabled: function() {
                    this.setDisabledStatus();
                }
            },
            methods: {
                setDisabledStatus: function(){
                    var vm = this;
                    $(vm.$el).prop('disabled', vm.isDisabled);
                }
            },
            mounted: function() {
                var vm = this;
                $(vm.$el).select2({
                    data: vm.options,
                    allowClear: vm.placeholder,
                    placeholder: vm.placeholder,
                    theme: "bootstrap",
                    language: "es"
                })
                    .val(vm.value)
                    .trigger('change')
                    .on('change', function (e) {
                        if(vm.current != this.value) {
                            vm.current = this.value;

                            if(!vm.isDisabled) {
                                vm.$emit('input', this.value);
                            }
                        }
                    });

                this.setDisabledStatus();
            },
            destroyed: function() {
                var vm = this;
                $(vm.$el).off().select2('destroy');
            }
        });
        Vue.component('datepicker', Datepicker);
        Vue.component('autocomplete', vueTypeahead);
        Vue.component('reservation-voucher', reservationVoucher);
        Vue.component('extend-dialog', extendDialog);
        Vue.component('reservation-list-filter-form', reservationListFilterForm);
        Vue.component('reservation-list-reservation-details', reservationListReservationDetails);
        // Vue.component('confirm-dialog', VuejsDialog);
        Vue.component('loading', Loading);
        //Vue.component('reservation-list-template', reservationList);
        var app = new Vue({
            el: '#reservation-report',
            model: {prop: 'filter'},
            data:{
                lists: JSON.parse(reservation_report.dataset.lists).results,
            },
            template: "<reservation-report :resultUrls='lists' />",
            components: {
                reservationReport
            },
        });

    }
});
<template>
    <div id="reservation-list-reservation-details">
        <div class="row">
            <div class="col-md-4 col-sm-6 col-xs-12">
                <table class="details-data">
                    <tr><th>Referencia:</th><td>{{ r.reference }}</td></tr>
                    <tr v-show="isPresent(r.extended_child)"><th>Prórrogada por:</th><td>{{ r.extended_child }}</td></tr>
                    <tr v-show="isPresent(r.go_on_child)"><th>Continuada por:</th><td>{{ r.go_on_child }}</td></tr>
                    <tr v-show="isPresent(r.extended_parent)"><th>Prórroga de:</th><td>{{ r.extended_parent }}</td></tr>
                    <tr v-show="isPresent(r.go_on_parent)"><th>Continuada de:</th><td>{{ r.go_on_parent }}</td></tr>
                    <tr><th>Fecha Creada:</th><td>{{ r.created_at }}</td></tr>
                    <tr><th>Fecha Modificada:</th><td>{{ r.updated_at }}</td></tr>
                    <tr><th>Cliente:</th><td>{{ r.client_first_name }} {{ r.client_last_name }}</td></tr>
                    <tr><th>Correo:</th><td>{{ r.client_email }}</td></tr>
                    <tr><th>Pasaporte:</th><td>{{ r.client_passport }}</td></tr>
                    <tr><th>País:</th><td>{{ r.client_country }}</td></tr>
                    <tr v-show="isPresent(r.business_reference2)"><th>Identificador #1:</th><td>{{ r.business_reference2 }}</td></tr>
                    <tr v-show="isPresent(r.business_reference3)"><th>Identificador #2:</th><td>{{ r.business_reference3 }}</td></tr>
                </table>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12">
                <table class="details-data">
                    <tr><th>Sucursal:</th><td>{{ r.dealer }}</td></tr>
                    <tr><th>Categoría:</th><td>{{ r.service }}</td></tr>
                    <tr><th>Vuelo de Arribo:</th><td>{{ r.client_flight }}</td></tr>
                    <tr><th>Fecha Recogida:</th><td>{{ r.from_date }}</td></tr>
                    <tr><th>Oficina Recogida:</th><td>{{ r.pick_up_location }}</td></tr>
                    <tr><th>Fecha Entrega:</th><td>{{ r.to_date }}</td></tr>
                    <tr><th>Oficina Entrega:</th><td>{{ r.drop_off_location }}</td></tr>
                    <tr><th>Días:</th><td>{{ r.days }}</td></tr>
                </table>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12">
                <table class="details-data">
                    <tr v-show="isPresent(r.tariff)"><th>Tarifa:</th><td>{{ r.tariff }}</td></tr>
                    <tr v-show="isPresent(r.modality)"><th>Modalidad:</th><td>{{ r.modality }}</td></tr>
                    <tr v-show="isPresent(r.price)"><th>Precio:</th><td>{{ r.price }}</td></tr>
                    <tr><th>Estado:</th><td>{{ r.status }}</td></tr>
                    <tr><th>Contrato: </th><td>{{ r.contract }}</td></tr>
                    <tr><th>Agencia:</th><td>{{ r.agency }}</td></tr>
                    <tr><th>Atendido por:</th><td>{{ r.user_name }}</td></tr>
                    <tr><th>Teléfonos:</th><td>{{ r.user_phone }}</td></tr>
                    <tr><th>Información de Negocio:</th><td>{{ r.user_info }}</td></tr>
                </table>
            </div>
            <div v-show="isPresent(r.client_details)" class="col-md-12 col-sm-12 col-xs-12">
                <table class="details-data">
                    <tr><th>Otras Aclaraciones:</th><td style="white-space: pre-line;">{{ r.client_details }}</td></tr>
                </table>
            </div>
        </div>

        <template v-for="rh in history" v-if="rh.seq > 0">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12 details-divider">
                    <div class="hr-text hr-text-left"><span class="badge badge-pill bg-blue text-white">{{ rh.seq }}</span></div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-6 col-xs-12">
                    <table class="details-data">
                        <tr><th>Fecha Modificada:</th><td>{{ rh.updated_at }}</td></tr>
                        <tr>
                            <th :class="{'diff': (rh.diffs.client_first_name || rh.diffs.client_last_name)}">Cliente:</th>
                            <td>{{ rh.client_first_name }} {{ rh.client_last_name }}</td>
                        </tr>
                        <tr><th :class="{'diff': rh.diffs.client_email}">Correo:</th><td>{{ rh.client_email }}</td></tr>
                        <tr><th :class="{'diff': rh.diffs.client_passport}">Pasaporte:</th><td>{{ rh.client_passport }}</td></tr>
                        <tr><th :class="{'diff': rh.diffs.client_country}">País:</th><td>{{ rh.client_country }}</td></tr>
                        <tr v-show="rh.diffs.business_reference2 || isPresent(rh.business_reference2)"><th :class="{'diff': rh.diffs.business_reference2}">Identificador #1:</th><td>{{ rh.business_reference2 }}</td></tr>
                        <tr v-show="rh.diffs.business_reference3 || isPresent(rh.business_reference3)"><th :class="{'diff': rh.diffs.business_reference3}">Identificador #2:</th><td>{{ rh.business_reference3 }}</td></tr>
                    </table>
                </div>
                <div class="col-md-4 col-sm-6 col-xs-12">
                    <table class="details-data">
                        <tr><th :class="{'diff': rh.diffs.dealer}">Sucursal:</th><td>{{ rh.dealer }}</td></tr>
                        <tr><th :class="{'diff': rh.diffs.service}">Categoría:</th><td>{{ rh.service }}</td></tr>
                        <tr><th :class="{'diff': rh.diffs.client_flight}">Vuelo de Arribo:</th><td>{{ rh.client_flight }}</td></tr>
                        <tr><th :class="{'diff': rh.diffs.from_date}">Fecha Recogida:</th><td>{{ rh.from_date }}</td></tr>
                        <tr><th :class="{'diff': rh.diffs.pick_up_location}">Oficina Recogida:</th><td>{{ rh.pick_up_location }}</td></tr>
                        <tr><th :class="{'diff': rh.diffs.to_date}">Fecha Entrega:</th><td>{{ rh.to_date }}</td></tr>
                        <tr><th :class="{'diff': rh.diffs.drop_off_location}">Oficina Entrega:</th><td>{{ rh.drop_off_location }}</td></tr>
                    </table>
                </div>
                <div class="col-md-4 col-sm-6 col-xs-12">
                    <table class="details-data">
                        <tr v-show="isPresent(rh.tariff) || rh.diffs.tariff"><th :class="{'diff': rh.diffs.tariff}">Tarifa:</th><td>{{ rh.tariff }}</td></tr>
                        <tr v-show="isPresent(rh.modality) || rh.diffs.modality"><th :class="{'diff': rh.diffs.modality}">Modalidad:</th><td>{{ rh.modality }}</td></tr>
                        <tr v-show="isPresent(rh.price) || rh.diffs.price"><th :class="{'diff': rh.diffs.price}">Precio:</th><td>{{ rh.price }}</td></tr>
                        <tr><th :class="{'diff': rh.diffs.contract}">Contrato: </th><td>{{ rh.contract }}</td></tr>
                        <tr><th :class="{'diff': rh.diffs.agency}">Agencia:</th><td>{{ rh.agency }}</td></tr>
                        <tr><th :class="{'diff': rh.diffs.user_name}">Atendido por:</th><td>{{ rh.user_name }}</td></tr>
                        <tr><th :class="{'diff': rh.diffs.user_phone}">Teléfonos:</th><td>{{ rh.user_phone }}</td></tr>
                        <tr><th :class="{'diff': rh.diffs.user_info}">Información de Negocio:</th><td>{{ rh.user_info }}</td></tr>
                    </table>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <table class="details-data">
                        <tr><th :class="{'diff': rh.diffs.client_details}">Otras Aclaraciones:</th><td style="white-space: pre-line;">{{ rh.client_details }}</td></tr>
                    </table>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
//Vue.component('reservation-list-reservation-details', {
//template: '#reservation-list-reservation-details-template',
export default {
    props: ['r', 'history']
}
</script>
import { Controller } from "stimulus"
import $ from 'jquery';
// import {atob} from "buffer";
// import rentType_controller from "./rentType_controller";
// require('block-ui/jquery.blockUI');

export default class extends Controller {
    connect() {
    }

    /**
     * FUNCIÓN SI SE MARCA QUE UN PRODUCTO ES DEL TIPO DE BOOKING ES AUTO PERMITE PONER SI ES 24 HORAS O NO.
     */
    twenty_four_hours(){
        /**
         * Si es un tipo de booking Auto, puede ser 24 horas o no
         * Si no es auto, entonces no puede ser 24, no hay fechas en otros tipos de booking.
         */
        if($('#service_booking_type option').filter(':selected').val() ==='car'){
            $("#twenty_four_hours").removeClass('disabled');
            $("#twenty_four_hours").removeAttr('disabled');
        }else{
            $("#twenty_four_hours").addClass('disabled');
            $("#twenty_four_hours").attr('disabled', true);
        }

        if($('#service_hour_booking').is(":checked")== false ){
            $("#twenty_four_hours").removeClass('disabled');
            $("#twenty_four_hours").removeAttr('disabled');
        }else{
            $("#twenty_four_hours").addClass('disabled');
            $("#twenty_four_hours").attr('disabled', true);
        }

    }


}

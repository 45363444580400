<template>
  <div id="reservation-list-filter-form">
    <form>
      <div class="row">
        <div class="col-md-3 col-sm-4 col-xs-12 mb-3">
          <div class="form-group">
            <label>Referencia</label>
            <input :disabled="locked" placeholder="Referencia..." v-model="filter.reference" class="form-control" type="text" name="reference"/>
          </div>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-12 mb-3">
          <div class="form-group">
            <label>Categoría</label>
            <select2 :disabled="locked" :options="filterlists.entity_services" placeholder=" - Categoría - " v-model="filter.entity_service" name="category"/>
          </div>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-12 mb-3">
          <div class="form-group">
            <label>Contrato</label>
            <select2 :disabled="locked" :options="filterlists.entity_contracts" placeholder=" - Contrato - " v-model="filter.entity_contract" name="contract"/>
          </div>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-12 mb-3">
          <div class="form-group">
            <label>Estado</label>
            <select2 :disabled="locked" :options="filterlists.statuses" placeholder=" - Estado - " v-model="filter.status" name="status"/>
          </div>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-12 mb-3">
          <div class="form-group">
            <label>Nombre de Cliente</label>
            <input :disabled="locked" placeholder="Nombre..." v-model="filter.client_name" class="form-control" type="text" name="client_name"/>
          </div>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-12 mb-3">
          <div class="form-group">
            <label>Apellido de Cliente</label>
            <input :disabled="locked" placeholder="Apellido..." v-model="filter.client_lastname" class="form-control" type="text" name="client_lastname"/>
          </div>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-12 mb-3">
          <div class="form-group">
            <label>Oficina Recogida</label>
            <select2 :disabled="locked" :options="filterlists.pickup_offices" placeholder=" - Oficina Recogida - " v-model="filter.pickup_office" name="pickup_office"/>
          </div>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-12 mb-3">
          <div class="form-group">
            <label>Oficina Entrega</label>
            <select2 :disabled="locked" :options="filterlists.dropoff_offices" placeholder=" - Oficina Entrega - " v-model="filter.dropoff_office" name="dropoff_office"/>
          </div>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-12 mb-3">
          <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-12 mb-3">
              <div class="form-group">
                <label>Desde</label>
                <datepicker inputClass="form-control"
                            :bootstrapStyling="true"
                            :clear-button="true"
                            :disabled="locked"
                            placeholder="mínimo"
                            v-model="filter.from_date_min"
                            :language="languages['es']"
                            name="from_date_min"
                            id="from_date_min"
                            autocomplete="off"></datepicker>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12 mb-3">
              <div class="form-group daterange-dot">
                <datepicker inputClass="form-control"
                            :bootstrapStyling="true"
                            :clear-button="true"
                            :disabled="locked"
                            placeholder="máximo"
                            v-model="filter.from_date_max"
                            :language="languages['es']"
                            name="from_date_max"
                            id="from_date_max"
                            autocomplete="off"
                            :disabled-dates="from_date_max_rules"
                ></datepicker>
                <!--                            <datetimepicker :disabled="locked" :rules="from_date_max_rules" placeholder="máximo" v-model="filter.from_date_max" :format="dateFormat" name="from_date_max" id="from_date_max" data-toggle="datetimepicker" data-target="#from_date_max"/>-->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-12 mb-3">
          <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-12 mb-3">
              <div class="form-group">
                <label>Hasta</label>
                <datepicker inputClass="form-control"
                            :bootstrapStyling="true"
                            :clear-button="true"
                            :disabled="locked"
                            placeholder="mínimo"
                            v-model="filter.to_date_min"
                            :language="languages['es']"
                            name="to_date_min"
                            id="to_date_min"
                            autocomplete="off"
                            :disabled-dates="to_date_min_rules"
                ></datepicker>
                <!--                            <datetimepicker :disabled="locked" :rules="to_date_min_rules" placeholder="mínimo" v-model="filter.to_date_min" :format="dateFormat" name="to_date_min" id="to_date_min" data-toggle="datetimepicker" data-target="#to_date_min"/>-->
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12 mb-3">
              <div class="form-group daterange-dot">
                <datepicker inputClass="form-control"
                            :bootstrapStyling="true"
                            :clear-button="true"
                            :disabled="locked"
                            placeholder="máximo"
                            v-model="filter.to_date_max"
                            :language="languages['es']"
                            name="to_date_max"
                            id="to_date_max"
                            autocomplete="off"
                            :disabled-dates="to_date_max_rules"
                ></datepicker>
                <!--                            <datetimepicker :disabled="locked" :rules="to_date_max_rules" placeholder="máximo" v-model="filter.to_date_max" :format="dateFormat" name="to_date_max" id="to_date_max"  data-toggle="datetimepicker"data-target="#to_date_max"/>-->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-12 mb-3">
          <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-12 mb-3">
              <div class="form-group">
                <label>Creada</label>
                <datepicker inputClass="form-control"
                            :bootstrapStyling="true"
                            :clear-button="true"
                            :disabled="locked"
                            placeholder="mínimo"
                            v-model="filter.created_at_min"
                            :language="languages['es']"
                            name="created_at_min"
                            id="created_at_min"
                            autocomplete="off"
                            :disabled-dates="created_at_min_rules"
                ></datepicker>
                <!--                            <datetimepicker :disabled="locked" :rules="created_at_min_rules" placeholder="mínimo" v-model="filter.created_at_min" :format="dateFormat" name="created_at_min" id="created_at_min" data-toggle="datetimepicker" data-target="#created_at_min"/>-->
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12 mb-3">
              <div class="form-group daterange-dot">
                <datepicker inputClass="form-control"
                            :bootstrapStyling="true"
                            :clear-button="true"
                            :disabled="locked"
                            placeholder="máximo"
                            v-model="filter.created_at_max"
                            :language="languages['es']"
                            name="created_at_max"
                            id="created_at_max"
                            autocomplete="off"
                            :disabled-dates="created_at_max_rules"
                ></datepicker>
                <!--                            <datetimepicker :disabled="locked" :rules="created_at_max_rules" placeholder="máximo" v-model="filter.created_at_max" :format="dateFormat" name="created_at_max" id="created_at_max" data-toggle="datetimepicker" data-target="#created_at_max"/>-->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-12 mb-3">
          <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-12 mb-3">
              <div class="form-group">
                <label>Actualizada</label>
                <datepicker inputClass="form-control" :bootstrapStyling="true" :clear-button="true"
                            :disabled="locked" placeholder="mínimo" v-model="filter.updated_at_min"
                            :language="languages['es']" name="updated_at_min" id="updated_at_min"
                            autocomplete="off" :disabled-dates="updated_at_min_rules"></datepicker>
                <!--                            <datetimepicker :disabled="locked" :rules="updated_at_min_rules" placeholder="mínimo" v-model="filter.updated_at_min" :format="dateFormat" name="updated_at_min" id="updated_at_min" data-toggle="datetimepicker" data-target="#updated_at_min"/>-->
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12 mb-3">
              <div class="form-group daterange-dot">
                <datepicker inputClass="form-control" :bootstrapStyling="true" :clear-button="true" :disabled="locked"
                            placeholder="máximo" v-model="filter.updated_at_max" :language="languages['es']"
                            name="updated_at_max" id="updated_at_max" autocomplete="off" :disabled-dates="updated_at_max_rules"
                ></datepicker>
                <!--                            <datetimepicker :disabled="locked" :rules="updated_at_max_rules" placeholder="máximo" v-model="filter.updated_at_max" :format="dateFormat" name="updated_at_max" id="updated_at_max" data-toggle="datetimepicker" data-target="#updated_at_max"/>-->
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3 col-sm-4 col-xs-12 mb-3">
          <div class="form-group">
            <label>¿Check In?</label>
            <select2 :disabled="locked" :options="[ {id: 0, text: 'No'}, {id: 1, text: 'Si'}]" placeholder=" - ¿Check In? - " v-model="filter.check_in" name="check_in"/>
          </div>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-12 mb-3">
          <div class="form-group">
            <label>Usuario</label>
            <select2 :disabled="locked" :options="filterlists.users" placeholder=" - Usuario - " v-model="filter.user" name="user"/>
          </div>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-12 mb-3">
          <div class="form-group">
            <label>Sucursal</label>
            <select2 :disabled="locked" :options="filterlists.dealers" placeholder=" - Sucursal - " v-model="filter.dealer" name="dealer"/>
          </div>
        </div>

      </div>
      <div class="form-footer">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12 mb-3">
            <div class="btn-list">
              <b class="btn btn-warning ml-auto" @click="cleanFilter" title="Limpiar Parámetros"><i class="fa fa-eraser"></i> Limpiar</b>
              <b class="btn btn-primary" @click="$emit('search')" title="Buscar Reservas"><i class="fa fa-search"></i> Buscar</b>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="hr-text hr-text-left text-green">Reservas</div>
  </div>
</template>

<script>
import * as lang from "vuejs-datepicker/src/locale";
export default {
  model: {
    prop: 'filter'
  },
  props: ['inprogress', 'filterlists', 'filter', 'locked', 'rules' ],
  data: function () {
    return {
      dateFormat: 'dd/MM/yy',
      language: 'es',
      languages: lang
    };
  },
  computed: {
    from_date_max_rules: function() {
      let vm = this;
      return vm.isPresent(vm.filter.from_date_min) ? {to:new Date(vm.filter.from_date_min)} : {};
    },
    to_date_min_rules: function() {
      let vm = this;
      return vm.isPresent(vm.filter.from_date_min) ? {to: new Date(vm.filter.from_date_min)} : {};
    },
    to_date_max_rules: function() {
      let vm = this;
      return vm.isPresent(vm.filter.to_date_min) ? {to: new Date(vm.filter.to_date_min)} : {};
    },
    created_at_min_rules: function() {
      let vm = this;
      return vm.isPresent(vm.filterlists.rules) ? {to: new Date(vm.filterlists.rules.created_at_min)} : {from: new Date()};
    },
    created_at_max_rules: function() {
      let vm = this;
      let maxd = vm.isPresent(vm.filterlists.rules) ? new Date(vm.filterlists.rules.created_at_max) : new Date();
      let min = null;
      if (vm.isPresent(vm.filter.created_at_min)) {
        min = new Date(vm.filter.created_at_min);
      }
      return {to: min, from: maxd};
    },
    updated_at_min_rules: function() {
      let vm = this;
      let ma = vm.isPresent(vm.filterlists.rules) ? new Date(vm.filterlists.rules.updated_at_min) : new Date();
      let min = null;
      if (vm.isPresent(vm.filter.created_at_min)) {
        min = new Date(vm.filter.created_at_min);
      }
      return {to: min, from: ma};
    },
    updated_at_max_rules: function() {
      let vm = this;
      let min = null;
      let mx = vm.isPresent(vm.filterlists.rules) ? new Date(vm.filterlists.rules.updated_at_max) : new Date();
      if (vm.isPresent(vm.filter.updated_at_min)) {
        min = new Date(vm.filter.updated_at_min);
      } else if (vm.isPresent(vm.filter.created_at_min, vm.dateFormat)) {
        min = new Date(vm.filter.created_at_min);
      }
      return {to: min, from: mx};
    }
  },
  watch: {
    'filter.from_date_min': function(from_date_min) {
      var vm = this;
      if(vm.isPresent(from_date_min)) {
        if(vm.isPresent(vm.filter.from_date_max) && moment(from_date_min, vm.dateFormat) >  moment(vm.filter.from_date_max, vm.dateFormat)){
          vm.filter.from_date_max = new Date(from_date_min);
        }

        if(vm.isPresent(vm.filter.to_date_min) && moment(from_date_min, vm.dateFormat) >  moment(vm.filter.to_date_min, vm.dateFormat)){
          vm.filter.to_date_min = new Date(from_date_min);
        }

        if(vm.isPresent(vm.filter.to_date_max) && moment(from_date_min, vm.dateFormat) >  moment(vm.filter.to_date_max, vm.dateFormat)){
          vm.filter.to_date_max = new Date(from_date_min);
        }
      }
    },
    'filter.to_date_min': function(to_date_min) {
      var vm = this;
      if(vm.isPresent(to_date_min) && vm.isPresent(vm.filter.to_date_max)) {
        if (moment(to_date_min, vm.dateFormat) > moment(vm.filter.to_date_max, vm.dateFormat)) {
          vm.filter.to_date_max = new Date(to_date_min);
        }
      }
    },
    'filter.created_at_min': function(created_at_min) {
      var vm = this;
      if(vm.isPresent(created_at_min)) {
        if(vm.isPresent(vm.filter.created_at_max) && moment(created_at_min, vm.dateFormat) >  moment(vm.filter.created_at_max, vm.dateFormat)){
          vm.filter.created_at_max = new Date(created_at_min);
        }
        if(vm.isPresent(vm.filter.updated_at_min) && moment(created_at_min, vm.dateFormat) >  moment(vm.filter.updated_at_min, vm.dateFormat)){
          vm.filter.updated_at_min = new Date(created_at_min);
        }
        if(vm.isPresent(vm.filter.updated_at_max) && moment(created_at_min, vm.dateFormat) >  moment(vm.filter.updated_at_max, vm.dateFormat)){
          vm.filter.updated_at_max = new Date(created_at_min);
        }
      }
    },
    'filter.updated_at_min': function(updated_at_min) {
      var vm = this;
      if(vm.isPresent(updated_at_min) && vm.isPresent(vm.filter.updated_at_max)
          && moment(updated_at_min, vm.dateFormat) >  moment(vm.filter.updated_at_max, vm.dateFormat)) {
        vm.filter.updated_at_max = new Date(updated_at_min);
      }
    }
  },
  methods: {
    cleanFilter: function() {
      this.filter.reference = '';
      this.filter.entity_service = '';
      this.filter.entity_contract = '';
      this.filter.dealer = '';
      this.filter.from_date_min =  new Date();
      this.filter.from_date_max =  new Date();
      this.filter.to_date_min = null;
      this.filter.to_date_max = null;
      this.filter.created_at_min = null;
      this.filter.created_at_max = null;
      this.filter.updated_at_min = null;
      this.filter.updated_at_max = null;
      this.filter.pickup_office = '';
      this.filter.dropoff_office = '';
      this.filter.status = 'confirmed';
      this.filter.check_in = 0;
      this.filter.client_name = '';
      this.filter.client_lastname = '';
    }
  }
}
</script>
import { Controller } from "stimulus"
import $ from 'jquery';
require("jquery-ui-dist/jquery-ui.css")
require('jquery-ui-dist/jquery-ui')
require('moment/moment')
require('block-ui/jquery.blockUI')
export default class extends Controller {
    connect() {
        let minDate = this.data.get('min');
        let maxDate = this.data.get('max');
        let beforeS = this.data.get('before');
        if(beforeS === 'true'){
            $('.cur_item_from_date').datepicker({
                dateFormat: "dd/mm/y",
                changeMonth: true,
                changeYear: true,
                numberOfMonths: 1,
                beforeShow: function(i) { if ($(i).attr('readonly')) { return false; } },
                minDate: new Date(minDate),
                maxDate: new Date(maxDate),
                onSelect: function(dateText, inst) {
                    let event = new Event('change', { bubbles: true }) // fire a native event
                    this.dispatchEvent(event);
                }
            });
        }else{
            $('.cur_item_from_date').datepicker({
                dateFormat: "dd/mm/y",
                changeMonth: true,
                changeYear: true,
                numberOfMonths: 1,
                minDate: new Date(minDate),
                onSelect: function(dateText, inst) {
                    let event = new Event('change', { bubbles: true }) // fire a native event
                    this.dispatchEvent(event);
                }
            });
        }

        $.datepicker.regional['es'] = {
            closeText: 'Cerrar',
            prevText: '<Ant',
            nextText: 'Sig>',
            currentText: 'Hoy',
            monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            monthNamesShort: ['Ene','Feb','Mar','Abr', 'May','Jun','Jul','Ago','Sep', 'Oct','Nov','Dic'],
            dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
            dayNamesShort: ['Dom','Lun','Mar','Mié','Juv','Vie','Sáb'],
            dayNamesMin: ['Do','Lu','Ma','Mi','Ju','Vi','Sá'],
            weekHeader: 'Sm',
            dateFormat: 'dd/mm/yy',
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ''
        };
        $.datepicker.setDefaults($.datepicker.regional['es']);
    }

    updateItemFromDate() {
        let url = this.data.get('url');
        $.blockUI({ message:false });
        $.ajax({
            type: "POST",
            url: url,
            data: $('#book_to_reservation').serialize(),
            success: (data) => {
                $.unblockUI();
            },
            error: function(jqXHR, textStatus, errorThrown) {
                $.unblockUI();
                if (jqXHR.getResponseHeader('REQUIRES_AUTH') === '1') {
                    alert('Su session expiró, por favor acceda nuevamente');
                    window.location = jqXHR.getResponseHeader('ROOT_URL');
                }else{
                    let message = "";
                    if (jqXHR.status === 0) {
                        message = 'Sin conexión: Verifique su conexión de red.';
                    } else if(jqXHR.status == 400){
                        message = ' Existen problemas en el request';
                    } else if(jqXHR.status == 401){
                        message = 'Autentiquece nuevamente.';
                    }else if (jqXHR.status == 403) {
                        message = "Autentiquece nuevamente.";
                    } else if (jqXHR.status == 404) {
                        message = 'Página no encontrada [404]';
                    } else if (jqXHR.status == 500) {
                        message = 'Ha ocurrido un error [500] que fue notificado al equipo de soporte, intene nuevamente en unos minutos.';
                    } else if (jqXHR.status == 503){
                        message = "Service unavailable.";
                    } else if (textStatus === 'parsererror') {
                        message = 'Ha ocurrido un error de interno [JSON] que fue notificado al equipo de soporte, intene nuevamente en unos minutos';
                    } else if (textStatus === 'timeout') {
                        message = 'Time out, tiempo de espera superado, inicie nuevmente, verifique su conexión de red.';
                    } else if (textStatus === 'abort') {
                        message = 'Request abortado.';
                    } else {
                        message = 'Error desconocido: ' + jqXHR.responseText;
                    }
                    // responseText
                    $.ajax({
                        async: true,
                        evalScripts:true,
                        type: "POST",
                        url: "/mail_error?errorStatus="+ jqXHR.status +"&clientMessage=" + message + "&errorThrown=" + errorThrown + "&url=" + this.url,
                        data: {error: jqXHR.responseText}
                    });
                    alert(message);
                }
                alert(message);
            }
        })
    }
}
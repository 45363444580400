<template>
    <div id="reservation-list-template">
        <div id="app">
          <notifications position="top center"/>
        </div>
        <div class="row">
            <div class="col-md-12 col-xs-12">
                <reservation-voucher :reservation="voucher" @close="voucher = null"/>
            </div>
            <div class="col-md-12 col-xs-12">
                <div id="reservation-list" class="card" v-blockui="lockedList">
                    <div class="card-status-top bg-green"></div>
                    <!-- Cabecera %>-->
                    <div class="card-header">
                        <div class="col-auto">
                            <h3 class="card-title">LISTADO DE RESERVAS</h3>
                        </div>
                        <div class="col-auto ml-auto">
                            <div class="action-btn ml-auto mr-3">
                                <div class="dropdown export-btns"  v-if="results.total_reservations > 0">
                                    <button class="btn btn-secondary  btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Exportar resultados de búsqueda">
                                        <i class="fas fa-download"></i>&nbsp;Exportar
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a :href="options.searchReservationsUrl + '?form_action=export&export_format=pdf'" target="_blank" class="dropdown-item" title="Descargar archivo con formato .pdf">
                                            <i class="fas fa-file-pdf export-icon"></i>&nbsp; Exportar como PDF
                                        </a>
                                        <a :href="options.searchReservationsUrl + '?form_action=export&export_format=excel'" target="_blank" class="dropdown-item" title="Descargar archivo con formato .xlsx">
                                            <i class="fas fa-file-excel"></i>&nbsp; Exportar como EXCEL
                                        </a>
                                        <a :href="options.searchReservationsUrl + '?form_action=export&export_format=csv'" target="_blank" class="dropdown-item" title="Descargar archivo con formato .csv">
                                            <i class="fas fa-file-csv"></i>&nbsp; Exportar como CSV
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-auto" v-bind:class='{"ml-auto":results.total_reservations < 0 }'>
                            <label class="form-check form-switch m-0">
                                <input class="form-check-input position-static" type="checkbox" checked  @click="showFilterForm = !showFilterForm" title="Mostrar/Ocultar Filtros" v-if="showFilterForm  ">
                                <input class="form-check-input position-static" type="checkbox"  @click="showFilterForm = !showFilterForm" title="Mostrar/Ocultar Filtros" v-else>
                            </label>
                        </div>
                    </div>
                    <div class="card-body">
                        <!-- Formulario de Filtro %>-->
                        <div class="filter" v-show="showFilterForm">
                            <reservation-list-filter-form :locked="locked" :filterlists="filter_lists" v-model="filter" @search="searchReservations('filter')" />
                            <!-- Listado de Errores -->
                            <div class="alert alert-danger alert-dismissible fade show" role="alert" v-show="errors.length > 0">
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                                <button type="button" class="close" @click="errors = []">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                        <!--  Listado de Reservas %>-->
                        <div>
                            <!-- Cabecera de listado de reservas %>-->
                            <form @submit.prevent class="mb-3">
                                <div class="d-flex">
                                    <div class="text-muted">
                                        Mostrando
                                        <div class="mx-2 d-inline-block">
                                            <select v-model="filter.count_by_page" :disabled="locked" @change="searchReservations('filter')" name="count_by_page" class="form-select count_by_page">
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div>
                                        elementos
                                    </div>
                                    <div class="ml-auto text-muted btn-list">
                                        <div class="ml-2 d-inline-block search-act">
                                            <input placeholder="Buscar..." v-model="filter.search" :disabled="locked" @keyup.enter="searchReservations('filter')" class="form-control search-input" type="text" name="search"/>
                                        </div>
                                        <a :href="options.rosterUrl" target="_blank" title="Descargar Roster" class="btn btn-outline-info btn-ico ml-2" >
                                            <i class="fas fa-file-archive"></i>
                                        </a>
                                    </div>
                                </div>
                            </form>
                            <!-- Tabla de listado de reservas-->
                            <div class="table-responsive">
                                <table class="table table-striped table-condensed table-hover">
                                    <thead class="thead-light">
                                    <tr>
                                        <th>REFERENCIA</th>
                                        <th>CLIENTE</th>
                                        <th>DESDE</th>
                                        <th>HASTA</th>
                                        <th>DÍAS</th>
                                        <th>CATEGORÍA</th>
                                        <th>OFICINA</th>
                                        <th>ESTADO</th>
                                        <th class="text-right">ACCIONES</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-if="results.reservation_list.length == 0">
                                        <td colspan="8">No existen elementos</td>
                                    </tr>
                                    <template v-else v-for="r in results.reservation_list">
                                        <tr :key="r.id" :class="{'recent-work': isCurrent(r)}">
                                            <td class="show-details" @click="showReservationDetails(r)"><i :class="{'fas fa-caret-right': isCurrent(r)}"></i>{{ r.reference }}</td>
                                            <td class="show-details" @click="showReservationDetails(r)">{{ r.client_first_name }} {{ r.client_last_name }}</td>
                                            <td class="show-details" @click="showReservationDetails(r)">{{ r.from_date }}</td>
                                            <td class="show-details" @click="showReservationDetails(r)">{{ r.to_date }}</td>
                                            <td class="show-details" @click="showReservationDetails(r)">{{ r.days }}</td>
                                            <td class="show-details" @click="showReservationDetails(r)">{{ r.service }}</td>
                                            <td class="show-details" @click="showReservationDetails(r)">{{ r.pick_up_location }}</td>
                                            <td class="show-details" @click="showReservationDetails(r)">{{ r.status }}</td>
                                            <td>
                                                <div class="action-btns text-right">
                                                    <a class="btn btn btn-outline-secondary btn-ico" title="Detalles de la reserva" :href="r.view_details"><i class="far fa-file-alt"></i></a>
                                                    <a v-show="r.show_billing_url['url'] !== ''" class="btn btn btn-outline-info btn-ico" v-bind:class="{'disabled': r.show_billing_url['disabled'] }" title="Comprobante de Pago" :href="r.show_billing_url['url']" target="_blank"><i class="fas fa-file-invoice"></i></a>
                                                    <a v-show="r.show_billing_url['url'] !== ''" class="btn btn btn-outline-info btn-ico" v-bind:class="{'disabled': r.invoice['disabled'] }" title="Datos de facturación" :href="r.invoice['url']"><i class="fas fa-file-invoice-dollar"></i></a>
                                                    <a class="btn btn-outline-secondary btn-ico" title="Descargar voucher" :href="options.voucherUrl + '?id=' + r.id" target="_blank"><i class="fas fa-download"></i></a>
                                                    <span class="btn btn-outline-secondary btn-ico" :title="'Mostar historial (' + r.total_modifications + ' modificaciones)'" @click="historyAction(r)"  v-bind:class="{disabled: r.total_modifications <= 0}" >
                                                        <i class="badge badge-pill bg-gray">{{ r.total_modifications }}</i>
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="details" :key="r.id + '_details'" v-show="r.show_details">
                                            <td colspan="8" class="voucher-content">
                                                <reservation-list-reservation-details :r="r" :history="results.history[r.id]" />
                                            </td>
                                        </tr>
                                    </template>
                                    </tbody>
                                </table>
                            </div>
                            <!--<%# Paginador de listado de reservas %>-->
                            <form @submit.prevent class="tablepager" v-if="showTablePager">
                                <div class="card-footer d-flex align-items-center">
                                    <div class="col">
                                        Página {{ results.page }} de {{ total_pages }} (Total de elementos {{ results.total_reservations }})
                                    </div>
                                    <div class="col">
                                        <div class="pagination m-0">
                                            <div class="ml-auto">
                                                <span class="btn btn-ghost-white" title="Primera página" v-bind:class="{disabled: locked || results.page <= 1}" :disabled="locked || results.page <= 1" @click="pagerArrows('first')"><i class="fas fa-step-backward"></i></span>
                                                <span class="btn btn-ghost-white" title="Página anterior" v-bind:class="{disabled: locked || results.page <= 1}" :disabled="locked || results.page <= 1" @click="pagerArrows('prev')"><i class="fas fa-chevron-left"></i></span>
                                            </div>
                                            <input type="text" class="form-control-rounded pagination-input" v-model="filter.page" title="Página actual" :disabled="locked" @keyup.enter="searchReservations" @input="filterPage">
                                            <div class="input-group-btn">
                                                <span class="btn btn-ghost-white" title="Página siguiente" v-bind:class="{disabled: locked || results.page >= total_pages}" :disabled="locked || results.page >= total_pages" @click="pagerArrows('next')"><i class="fas fa-chevron-right"></i></span>
                                                <span class="btn btn-ghost-white" title="Última página" v-bind:class="{disabled: locked || results.page >= total_pages}" :disabled="locked || results.page >= total_pages" @click="pagerArrows('last')"><i class="fas fa-step-forward"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    //Vue.component('reservation-list', {
        //template: '#reservation-list-template',
// import $
//     from "../../../../../../../../.rbenv/versions/2.6.6/lib/ruby/gems/2.6.0/gems/jquery-rails-4.4.0/vendor/assets/javascripts/jquery";

    export default {
    props: ["resultUrls"],
    data: function(){
        return {
            voucher: null,
            current: null,
            inprogress: false,
            filter: {
                reference: '',
                client_name: '',
                client_lastname: '',
                entity_service: '',
                entity_contract: '',
                dealer: '',
                from_date_min: null,
                from_date_max: null,
                to_date_min: null,
                to_date_max: null,
                created_at_min: null,
                created_at_max: null,
                updated_at_min: null,
                updated_at_max: null,
                pickup_office: '',
                dropoff_office: '',
                status: '',
                user: '',
                count_by_page: '10',
                search: '',
                page: '1'
            },
            filter_lists: {},
            results: {
                page: '1',
                total_reservations: '',
                reservation_list: [],
                history: {}
            },
            errors: [],
            locked: false,
            showFilterForm: true,

            cancel: {
                showDialog: false,
                noShowDialog: false,
                reservation: null,
                action: 'cancelar'
            },
            extend: {
                action: null,
                reservation: null
            },

            options: {
                searchReservationsUrl: this.resultUrls.search,
                loadReservationListsUrl: this.resultUrls.list,
                voucherUrl: this.resultUrls.voucher,
                historyUrl: this.resultUrls.history,
                rosterUrl: this.resultUrls.roster
            }
        };
    },
    computed: {
        total_pages: function() {
            var vm = this;
            var result = 1;

            if (vm.isPresent(vm.results) && vm.isPresent(vm.filter.count_by_page) &&
                vm.results.total_reservations > 0 && vm.filter.count_by_page > 0) {
                result = parseInt(vm.results.total_reservations/vm.filter.count_by_page);

                if (vm.results.total_reservations % vm.filter.count_by_page > 0) {
                    result += 1;
                }
            }
            return result;
        },
        showTablePager: function() {
            var vm = this;
            return vm.isPresent(vm.results) &&
                vm.isPresent(vm.total_pages) &&
                vm.total_pages > 0
                ;
        },
        // Define si se debe mostrar el voucher Ej cuando la reserva se confirma %>
        showVoucher: function() {
            return this.isPresent(this.voucher);
        },
        lockedList: function() {
            var vm = this;
            return vm.isPresent(vm.cancel.reservation) ||
                vm.isPresent(vm.extend.reservation) ||
                vm.locked
        }
    },
    watch: {
        current: function(current) {
            this.searchReservations();
        }
    },
    methods: {
        isCurrent: function(r) { return r.id == this.current; },
        //isDisabledEdit: function(r) { return !(r.can_edit == true); },
        //isDisabledCancel: function(r) { return (!(r.can_cancel == true) && !(r.can_interrupt == true)); },
        //isDisabledExtend: function(r) { return !(r.can_extend == true); },
        //isDisabledEditExtend: function(r) { return !(r.can_edit_extension == true); },
        //isDisabledAgency: function(r) { return ( r.is_agency == true); },
        //isSuperModify: function(r) { return ( r.is_super_modify == true); },
        //isSuperCancel: function(r) { return ( r.is_super_cancel == true); },
        pagerArrows: function(action) {
            var vm = this;
            switch(action) {
                case 'first':
                    vm.filter.page = 1;
                    break;
                case 'prev':
                    vm.filter.page--;
                    break;
                case 'next':
                    vm.filter.page++;
                    break;
                case 'last':
                    vm.filter.page = vm.total_pages;
                    break;
            }
            vm.searchReservations();
        },
        filterPage: function(e) {
            var vm = this;
            var value = e.target.value.replace(/[^0-9]*/g, '');
            if (!(value > 0)){
                vm.filter.page = '1';
               // Vue.nextTick(function () {
                this.nextTick(function () {
                    $('.tablepager input').select();
                })
            } else if (vm.isPresent(vm.results) && vm.isPresent(vm.total_pages) && value > parseInt(vm.total_pages)) {
                vm.filter.page = vm.total_pages;
            } else if(value != vm.filter.page) {
                vm.filter.page = value;
            }
        },
        showReservationDetails: function(r) { r.show_details = !r.show_details;},
        searchReservations: function(form_action, force) {
            var vm = this;
            var force = force || false;

            if (vm.locked && !force) {
                return;
            }
            var url = vm.options.searchReservationsUrl;
            var data = $.extend(true, {}, vm.filter);
            var async = true;
            if(form_action == 'init') {
                data['form_action'] = 'init';
                async = false;
            }
            if(form_action == 'filter') {
                data['form_action'] = 'filter';
            }
            vm.locked = true;
            vm.httpPost(url, data).done(function(response){
                if(vm.isBlank(response)) {
                    vm.errors = ['No fue posible recuperar información desde el servidor'];
                    return;
                }
                if(vm.isPresent(response.results)) {
                    vm.results = response.results;
                }
                else {
                    vm.results = {
                        page: '1',
                        total_reservations: '0',
                        reservation_list: [],
                        history: {}
                    }
                }
                if(vm.isPresent(response.filter)) {
                    vm.filter = response.filter;
                }

                if(vm.isPresent(response.errors)) {
                    vm.errors = response.errors;
                }
                else {
                    vm.errors = [];
                }
            }).fail(function(){
                vm.errors = ['Ha ocurrido un error al intentar acceder al servidor.'];
                vm.results = {
                    page: '1',
                    total_reservations: '0',
                    reservation_list: [],
                    history: {}
                }
            }).always(function() {
                vm.locked = false;
            });
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        },
        loadReservationLists: function() {
            var vm = this;
            var url = vm.options.loadReservationListsUrl;

            vm.locked = true;
            vm.httpPost(url, {}, false).done(function(response){
                if(vm.isPresent(response)) {
                    vm.filter_lists = response;
                }
            }).fail(function(){
                vm.errors = ['Ha ocurrido un error al intentar acceder al servidor.'];
            }).always(function() {
                vm.locked = false;
            });
        },
        historyAction: function(r) {
            var vm = this;
            var url = vm.options.historyUrl;
            vm.locked = true;
            vm.httpPost(url, {id: r.id}).done(function(response) {
                if(vm.isPresent(response)) {
                    if(vm.isPresent(response.errors)) {
                        vm.errors = response.errors;
                    }
                    else {
                        vm.results.history[r.id] = response.result
                        r.show_details = true
                    }
                }
            }).fail(function(){
                vm.errors = ['Ha ocurrido un error al intentar acceder al servidor.'];
            }).always(function() {
                vm.locked = false;
            });
        },
        updateInfo: function(voucher) {
            if (this.isPresent(voucher) && this.isPresent(voucher.id)) {
                this.current = voucher.id;
                this.voucher = voucher;
            } else {
                this.current = null;
                this.voucher = null;
            }
            this.inprogress = false;
        },
        onInProgress: function(inprogress) {
            this.inprogress = inprogress;
        },
        // <%# Funcion ajax, realiza un consulta via post de forma asincrona y retorna un objeto de tipo promise %>
        httpPost: function(url, data, async) {
            // <%# limpiar listado de errores %>
            this.errors = [];
            return $.ajax({
                type: 'POST',
                url: url,
                data: data,
                async: (async !== false)
            });
        },
        // <%# true si isPresent y no se esta haciendo un request al servidor %>
        isEnabled: function(data) { return this.isPresent(data) && !this.locked; }
    },
    mounted: function() {
        // cargar listados para el filtro
        this.loadReservationLists();
        // cargar datos de reservas y filtro
        this.searchReservations('init');
    }
}

</script>

<style>
    [v-cloak] { display: none; }
    #reservation-list .action-btn { text-align: right; }
    #reservation-list { margin-top: 20px; }
    #reservation-list hr { margin-top: 0px; }
    #reservation-list .count_by_page { max-width: 80px; }
    #reservation-list .search-act { text-align: right; }
    #reservation-list .search-act input {
        max-width: 180px;
        display: inline-block;
    }

    #reservation-list table tbody td {
        vertical-align: middle;
    }

    #reservation-list .tablepager .input-group .input-group-btn .btn {
        padding: 4px 12px;
        font-size: 10px;
        cursor: pointer;
        margin-right: 0px;
    }

    #reservation-list .tablepager .input-group-btn:first-child>.btn,  #reservation-list .tablepager .input-group-btn:first-child>.btn-group {
        margin-right: -1px;
    }

    #reservation-list .tablepager .input-group-addon:hover { background-color: #ddd; }
    #reservation-list .tablepager .input-group-addon:active { background-color: #ccc; }
    #reservation-list .tablepager {
        line-height: 24px;
    }
    #reservation-list .tablepager .input-group {
        width: 200px;
        float: right;
    }
    #reservation-list .tablepager input {
        text-align: center;
        height: 24px;
    }
    #reservation-list .errors .bs-callout-danger {
        margin: 0;
        padding: 15px 30px 15px 15px;
        border-left: 5px solid #dFb5b4;
        background-color: #fcf2f2;
        list-style: none;
    }
    #reservation-list .voucher-content {
        padding: 15px;
        background-color: white !important;
    }

    #reservation-list .show-details {
        cursor: pointer;
    }
    #reservation-list .voucher-content b {
        width: 150px;
        display: inline-block;
        text-align: right;
        margin-right: 15px;
    }

    #reservation-list .dropdown-menu {
        min-width: 100px;
    }

    #reservation-list .dropdown-menu-right.dropdown-menu:after {
        right: 5%;
    }

    #reservation-list .recent-work {
        background-color: #ededed;
        font-weight: bold;
    }

    #reservation-list td:first-child, #reservation-list th:first-child  {
        padding-left: 15px;
    }

    #reservation-list .recent-work td:first-child::before {
        /*content: '\f0da';*/
        /*font: normal normal normal 14px/1 FontAwesome;*/
        margin-left: -10px;
        font-size: 14px;
        padding-right: 5px;
    }

    #reservation-list .filter .daterange-dot:before {
        content: ':';
        float: left;
        margin-left: -13px;
        font-size: 20px;
        font-weight: bold;
    }

    @media (min-width: 768px) {
        #reservation-list .filter .daterange-dot {
            margin-top: 22px;
        }
    }


    #reservation-list .details .details-data th {
        text-align: right;
        width: 122px;
        vertical-align: top;
        padding: 0 0 10px 0;
        margin: 0 0 10px;
    }

    #reservation-list .details .details-data td {
        padding-left: 15px;
        padding-bottom: 10px;
    }

    #reservation-list .export-btns {
        margin-bottom: 5px;
        margin-right: 5px;
    }
    #reservation-list .export-btns .dropdown-menu { margin-top: 6px; }
    #reservation-list .export-btns .dropdown-menu li a {
        padding-top: 7px;
        padding-bottom: 7px;
        padding-left: 10px;
    }
    #reservation-list .export-btns .dropdown-menu li a i { margin-right: 9px; font-size: 18px; }
    #reservation-list .export-btns .btn .caret { margin-left: 0; }

    #reservation-list .export-btns .dropdown-menu {
        left: auto;
    }

    #reservation-list .action-btn .btn:last-child {
        margin-right: 0px;
    }

    #reservation-list .action-btns .btn[disabled] {
        opacity: .3;
    }

    #reservation-list .action-btns .btn {
        min-width: 25px;
    }

    #reservation-list .form-group.action-btn {
        margin-top: 5px;
    }

    #reservation-list .diff {
        text-decoration: line-through;
        color: #c70101;
    }

    #reservation-list .details td span {
        line-height: inherit;
    }

    #reservation-list .details-divider { margin-top: 20px; }

    #reservation-list .details-divider .badge {
        float: left;
        position: relative;
        display: inline-block;
        top: -11px;
        min-width: 23px;
        border-radius: 15px;
    }

    .centered-modal.in {
        display: flex !important;
    }
    .centered-modal .modal-dialog {
        margin: auto;
    }
    .btn_agency{
        color: #c70101;
    }
    .dropdown-menu>li>span {
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: 400;
        line-height: 1.42857143;
        color: #333;
        white-space: nowrap;
        cursor:pointer;
    }

    .pagination-input{
        text-align: center;
        width: 10%;
    }
</style>
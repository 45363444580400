import { Controller } from "stimulus"
import $ from 'jquery';

require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")
import reservationContract from "select2"
require('block-ui/jquery.blockUI')
export default class extends Controller {
    connect() {
        $('.card_holder_country').select2({
            theme: "bootstrap",
            language: "es"
        });
        $(".card_holder_country").on('select2:select', function () {
            let event = new Event('change', {bubbles: true}) // fire a native event
            this.dispatchEvent(event);
        });
    }

    validate(){
        var regex = /\A(|(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6})\z/i;
        if($("#id_card_holder_name").val() == '' || $("#id_card_holder_name").val() == 'null' ||
            regex.test($("#id_card_holder_email").val()) == false ||
            $("#id_card_holder_passport").val() == '' || $("#id_card_holder_passport").val() == 'null' ||
            $("#id_card_holder_country").val() == '' || $("#id_card_holder_country").val() == 'nul' ||
            $('#request_data_ok').is(":checked") == false){
            jQuery('#continue_button').prop('disabled', true);
            jQuery('#continue_button_button').prop('disabled', true);
            jQuery('#bank_transfer_indications').css('display', 'none');
        }else{
            jQuery('#bank_transfer_indications').css('display','block');
            jQuery('#continue_button').prop('disabled', false);
            jQuery('#continue_button_button').prop('disabled', false);
        }
    }
}
import { Controller } from "stimulus"
import $ from 'jquery';

export default class extends Controller {
    static targets = ["password_field"]
    password(e) {
        console.log('PASAA!!')
        if ($('.password_field')[0].type === "password") {
            $('.span_ico').empty();
            $('.span_ico').append('<!-- Download SVG icon from http://tabler-icons.io/i/eye-check -->\n' +
                '\t<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="12" r="2" /><path d="M12 19c-4 0 -7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7c-.42 .736 -.858 1.414 -1.311 2.033" /><path d="M15 19l2 2l4 -4" /></svg>'
            )
            $('.password_field').attr('type','text');
        } else {
            $('.span_ico').empty();
            $('.span_ico').append('<!-- Download SVG icon from http://tabler-icons.io/i/eye -->\n' +
                '\t<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="12" r="2" /><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" /></svg>'
            )
            $('.password_field').attr('type','password');
        }
    }
}

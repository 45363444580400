import { Controller } from "stimulus"
import $ from 'jquery';

require('block-ui/jquery.blockUI')
export default class extends Controller {
    static values = { allowed: Array }
    connect() {
        
    }

    showPaymentCurrency() {
        $.blockUI({ message:false });
        let currency = this.data.get('currency');
        let allowed_currencies = this.allowedValue
        let select_value = $( "#payment_type option:selected" ).val();
        let opt = ''
        switch(select_value) {
            case 'pi':
                if(currency !== undefined && currency !== null && currency !== ''){
                    $('#payment_currency').empty()
                    if( $.inArray(currency, allowed_currencies) !== -1 ) {
                       // Aquí pongo todos los currencies permitidos
                        let currency_description = ''
                        $.each(allowed_currencies, function( index, value ) {
                            if (value === 'EUR'){
                                currency_description = 'Euro EUR'
                            }else if(value === 'USD') {
                                currency_description = 'US Dollar USD'
                            }
                            opt =  '<option value='+currency+'>'+currency_description+'</option>'
                            $('#payment_currency').append(opt)
                        });

                       //  if (currency === 'EUR'){
                       //      currency_description = 'Euro EUR'
                       //  }else if(currency === 'USD') {
                       //      currency_description = 'US Dollar USD'
                       //  }
                       // opt =  '<option selected="selected" value='+currency+'>'+currency_description+'</option>'
                       //  $('#payment_currency').append(opt)
                    }else {
                        alert('No tiene pasarela configurada para el método de pago seleccionado y moneda de pago configurada')
                    }
                }else {
                    $('#payment_currency').empty()
                    if( $.inArray('EUR', allowed_currencies) !== -1 ) {
                        opt =  '<option selected="selected" value="EUR">Euro EUR</option>'
                        $('#payment_currency').append(opt)
                    }
                    if( $.inArray('USD', allowed_currencies) !== -1 ) {
                        $('#payment_currency').append('<option value="USD">US Dollar USD</option>')
                    }
                }
                break;
            case 'pn':
                $('#payment_currency').empty()
                if( $.inArray('USD', allowed_currencies) !== -1 ) {
                    opt =  '<option selected="selected" value="USD">US Dollar USD</option>'
                    $('#payment_currency').append(opt)
                }
                if( $.inArray('CUP', allowed_currencies) !== -1 ) {
                    $('#payment_currency').append('<option value="CUP">Cuban Peso CUP</option>')
                }
                break;
            case 'pf':
                $('#payment_currency').empty()
                    .append('<option selected="selected" value="EUR">Euro EUR</option>' +
                        ' <option value="USD">US Dollar USD</option>' +
                        ' <option value="CUP">Cuban Peso CUP</option>'
                    )
                break;
            case 'op':
                $('#payment_currency').empty()
                    .append('<option selected="selected" value="EUR">Euro EUR</option>' +
                        ' <option value="USD">US Dollar USD</option>' +
                        ' <option value="CUP">Cuban Peso CUP</option>'
                    )
                break;
            case 'tb':
                $('#payment_currency').empty()
                    .append('<option selected="selected" value="EUR">Euro EUR</option>' +
                        ' <option value="USD">US Dollar USD</option>' +
                        ' <option value="CUP">Cuban Peso CUP</option>'
                    )
                break;
            default:
                $('#payment_currency').empty()
        }
        $.unblockUI();
    }
}
import consumer from "./consumer"

consumer.subscriptions.create("RoomChannel", {
  connected() {
    //console.log("entra al método connected")
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    var message2 = ' <div class="chat-message-container msg-head" id=mh'+data.input_id+'>\n' +
       '          <div class="row no-gutters">' +
       '\n' +
       '            <div class="col">\n' +
       '              <div class="message-content left-chat message_content'+data.input_id +'" id=message-content'+data.input_id+'>\n' +
       '<img class="avatar-chat img-responsive" src="'+ data.picture_url +'" alt="'+data.alt +'" title="'+ data.alt+'">\n' +
       '              <p>'+ data.user +' escribió: </p>\n'+
       '                <p class="mb-1">\n' +
       '                  ' + data.content+'\n' +
       '                </p>\n' +
       '\n' +
       '                <div class="text-right">\n' +
       '                  <small>\n' +
       '                    '+ data.time+' \n' +
       '                  </small>\n' +
       '                </div>\n' +
       '              </div>\n' +
       '            </div></div>\n' +
       '        </div>'
    var textnode = document.createTextNode(data.content);
      jQuery('#new_message').append(message2);
      if( $('#'+data.input_id)[0] !== undefined){
          $('#'+data.input_id)[0].value = '';
      }
    //$("#chat").animate({ scrollTop: $('#chat').prop("scrollHeight")}, 1000);
    //document.getElementById('chat-sound'+data.input_id).play();

    // document.addEventListener("visibilitychange", function() {
     //console.log(data.mute != true || data.mute != 'true');
    // if(data.mute === 'a'){
      //document.getElementById('chat-sound').play();
       document.hidden ? document.getElementById('chat-sound').play() : "";
    // }
    document.title = document.hidden ? "Tiene mensajes nuevos" : "";

    // });
    // $('#chat-sound').play();
  }
});

import { Controller } from "stimulus"
import $ from 'jquery';

import reservationContract from "select2"
require('block-ui/jquery.blockUI')
export default class extends Controller {
    static targets = ["option", "input"]

    selectRadioOption() {
        //console.log(event.target.value);
        $.blockUI({message: false});
        $.ajax({
            type: "POST",
            url: this.data.get('url'),
            //data: {collect_mode:$(this).val(), tresdsecure:$('#tresdsecure').is(':checked')},
            data: {collect_mode:event.target.value, tresdsecure:false},
            success: (data) => {
                $.unblockUI();
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $.unblockUI();
                if (jqXHR.getResponseHeader('REQUIRES_AUTH') === '1') {
                    alert('Su session expiró, por favor acceda nuevamente');
                    window.location = jqXHR.getResponseHeader('ROOT_URL');
                } else {
                    let message = "";
                    if (jqXHR.status === 0) {
                        message = 'Sin conexión: Verifique su conexión de red.';
                    } else if (jqXHR.status == 400) {
                        message = ' Existen problemas en el request';
                    } else if (jqXHR.status == 401) {
                        message = 'Autentiquece nuevamente.';
                    } else if (jqXHR.status == 403) {
                        message = "Autentiquece nuevamente.";
                    } else if (jqXHR.status == 404) {
                        message = 'Página no encontrada [404]';
                    } else if (jqXHR.status == 500) {
                        message = 'Ha ocurrido un error [500] que fue notificado al equipo de soporte, intene nuevamente en unos minutos.';
                    } else if (jqXHR.status == 503) {
                        message = "Service unavailable.";
                    } else if (textStatus === 'parsererror') {
                        message = 'Ha ocurrido un error de interno [JSON] que fue notificado al equipo de soporte, intene nuevamente en unos minutos';
                    } else if (textStatus === 'timeout') {
                        message = 'Time out, tiempo de espera superado, inicie nuevmente, verifique su conexión de red.';
                    } else if (textStatus === 'abort') {
                        message = 'Request abortado.';
                    } else {
                        message = 'Error desconocido: ' + jqXHR.responseText;
                    }
                    // responseText
                    $.ajax({
                        async: true,
                        evalScripts: true,
                        type: "POST",
                        url: "/mail_error?errorStatus=" + jqXHR.status + "&clientMessage=" + message + "&errorThrown=" + errorThrown + "&url=" + this.url,
                        data: {error: jqXHR.responseText}
                    });
                    alert(message);
                }
                alert(message);
            }
        })
    }
}
<template>
  <div id="reservation-form" class="card" v-blockui="locked">
    <div class="card-status-top bg-green"></div>
    <div class="card-header">
      <div class="col-auto">
        <h3 class="card-title"><b>RESERVA</b></h3>
      </div>
      <div class="col-auto ml-auto">
        <label class="form-check form-switch m-0">
          <input class="form-check-input position-static" type="checkbox" checked  @click="showContent = !showContent" title="Mostrar/Ocultar Filtros" v-if="showContent  ">
          <input class="form-check-input position-static" type="checkbox"   @click="showContent = !showContent" title="Mostrar/Ocultar Filtros" v-else>
        </label>
      </div>
    </div>
    <div class="reservation-container card-body" v-show="showContent">
      <form class="booking-data">
        <div class="row">
          <div class="col-md-6 col-sm-12 col-xs-12 mb-3">
            <div class="form-group">
              <label class="required">Destino </label>
              <select2 :disabled="locked" :options="lists.destinations" placeholder=" - Provincia - " v-model="res.destination" @input="reservation" name="destination"/>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-xs-12 mb-3">
            <div class="form-group">
              <label class="required">Sucursal </label>
              <select2 :disabled="disableDealer" :options="lists.dealers" placeholder=" - Sucursal - " v-model="res.dealer" @input="reservation" name="dealer"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-12 col-xs-12 mb-3">
            <div class="form-group">
              <label class="required">Contrato </label>
              <select2 :disabled="disableEntityContract" :options="lists.entity_contracts" placeholder=" - Contrato - " v-model="res.entity_contract" @input="reservation" name="entity_contract"/>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-xs-12 mb-3">
            <div class="form-group">
              <label class="required">Categoría </label>
              <select2 :disabled="disableEntityService" :options="lists.entity_services" placeholder=" - Categoría - " v-model="res.entity_service" @input="reservation" name="entity_service"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-sm-12 col-xs-12 mb-3">
            <div class="form-group">
              <label class="required">Desde </label>
              <datetimepicker :disabled="disableFromDate" :rules="rules.from_date" placeholder="Fecha Recogida" id="from_date" data-target="#from_date" v-model="res.from_date" @input="reservation" format="DD/MM/YY HH:mm" name="from_date"/>
            </div>
          </div>
          <div class="col-md-3 col-sm-12 col-xs-12 mb-3">
            <div class="form-group">
              <label class="required">Hasta <span class="form-help" data-toggle="tooltip" data-placement="top" title="Producto 24 horas, si la hora de entrega tiene una diferencia de 5 horas con la hora de recogida, reserve un día más">?</span></label>
              <!--<datetimepicker :disabled="disableToDate" :rules="rules.to_date" placeholder="Fecha Entrega" id="to_date" data-target="#to_date" v-model="res.to_date" @input="reservation" format="DD/MM/YY HH:mm" name="to_date"/>-->
              <reservationDatePicker :disabled="disableToDate" :rules="rules.to_date" placeholder="Fecha Entrega" id="to_date" data-target="#to_date" v-model="res.to_date" @input="reservation" format="DD/MM/YY HH:mm" name="to_date"/>
            </div>
          </div>
          <div class="col-md-3 col-sm-12 col-xs-12 mb-3">
            <div class="form-group">
              <label class="required">Recogida </label>
              <select2 :disabled="disablePickupOffice" :options="lists.pickup_offices" placeholder=" - Oficina Recogida - " v-model="res.pickup_office" @input="reservation" name="pickup_office"/>
            </div>
          </div>
          <div class="col-md-3 col-sm-12 col-xs-12 mb-12">
            <div class="form-group">
              <label class="required">Entrega </label>
              <select2 :disabled="disableDropoffOffice" :options="lists.dropoff_offices" placeholder=" - Oficina Entrega - " v-model="res.dropoff_office" name="dropoff_office"/>
            </div>
          </div>
        </div>
        <div class="row" v-show="isPresent(rules['calculate_price']) && rules['calculate_price']">
          <div class="col-md-6 col-sm-12 col-xs-12 mb-12">
            <div class="form-group">
              <label class="required">Tarifa </label>
              <select2 :disabled="disableEntityTariff" :options="lists.entity_tariffs" placeholder=" - Tarifa - " v-model="res.entity_tariff" @input="reservation" name="entity_tariff"/>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-xs-12 mb-3">
            <div class="form-group">
              <label class="required">Modalidad </label>
              <select2 :disabled="disableEntityModality" :options="lists.entity_modalities" placeholder=" - Modalidad - " v-model="res.entity_modality" @input="reservation" name="entity_modality"/>
            </div>
          </div>
        </div>
        <div class="row" v-show="isPresent(rules['calculate_price']) && rules['calculate_price']">
          <div class="col-md-4 col-sm-12 col-xs-12 mb-3">
            <div class="form-group">
              <label class="required">Cantidad de Choferes adicionales </label>
              <select2 :disabled="disableEntityModality" :options="[{'id':0,'text':'0'},{'id':1,'text':'1'},{'id':2, 'text':'2'}]" placeholder=" - Cantidad de Choferes adicionales - " v-model="res.drivers" @input="reservation" name="drivers"/>
            </div>
          </div>
          <div class="col-md-4 col-sm-12 col-xs-12 mb-3">
            <div class="form-group">
              <label class="required">Aplicar suplemento de relevo de responsabilidad </label>
              <select2 :disabled="disableEntityModality" :options="[{'id':0,'text':'No'},{'id':1,'text':'Si'}]" placeholder=" - Aplicar suplemento de relevo de responsabilidad - " v-model="res.supplement_insurance" @input="reservation" name="supplement_insurance"/>
            </div>
          </div>
          <div class="col-md-4 col-sm-12 col-xs-12 mb-3" v-show="isPresent(rules['vip'])">
            <div class="form-group">
              <label class="required">Aplicar Servicio VIP </label>
              <select2 :disabled="disableEntityModality" :options="[{'id':0,'text':'No'},{'id':1,'text':'Si'}]" placeholder=" - Aplicar servicio salón VIP - " v-model="res.vip" @input="reservation" name="vip"/>
            </div>
          </div>
        </div>
        <div class="form-footer">
          <div class="row" v-show="!res.is_available">
            <div class="btn-list">
              <b class="btn btn-danger ml-auto" v-if="canCancelAction" @click="reservation('release')">Cancelar</b>
              <b class="btn btn-primary" :class="{disabled: !isBookinInfoReady}" @click="reservation('booking')">Continuar</b>
            </div>
          </div>
        </div>
      </form>

      <form class="client-data" v-show="res.is_available" style="margin-top: 15px;">
        <h5><b>INFORMACIÓN DE CLIENTE</b></h5>
        <hr/>
        <div class="row">
          <div class="col-md-4 col-sm-12 col-xs-12 mb-3">
            <div class="form-group">
              <label class="required">Vuelo </label>
              <input class="form-control" type="text" v-model="res.client_flight" placeholder="Vuelo" name="client_flight"/>
            </div>
          </div>
          <div class="col-md-4 col-sm-12 col-xs-12 mb-3">
            <div class="form-group">
              <label class="required">Nombre </label>
              <input class="form-control" type="text" v-model="res.client_name" placeholder="Nombre" name="client_name"/>
            </div>
          </div>
          <div class="col-md-4 col-sm-12 col-xs-12 mb-3">
            <div class="form-group">
              <label class="required">Apellido </label>
              <input class="form-control" type="text" v-model="res.client_lastname" placeholder="Apellido" name="client_lastname"/>
            </div>
          </div>
          <div class="col-md-4 col-sm-12 col-xs-12 mb-3">
            <div class="form-group">
              <label class="required">Correo </label>
              <!--                            <input class="form-control" type="text" v-model="res.client_email" placeholder="Correo Electrónico" name="client_email"/>-->
              <autocomplete :items="lists.emails" />
            </div>
          </div>
          <div class="col-md-4 col-sm-12 col-xs-12 mb-3">
            <div class="form-group">
              <label class="required">Pasaporte </label>
              <input class="form-control" type="text" v-model="res.client_passport" placeholder="Pasaporte" name="client_passport" required="required" pattern="^[a-zA-Z0-9\u00f1\u00d1]*$" minlength="4" maxlength="15">
            </div>
          </div>
          <div class="col-md-4 col-sm-12 col-xs-12 mb-3">
            <div class="form-group">
              <label>País </label>
              <select2 :disabled="disableCountry" :options="lists.countries" placeholder=" - País - " v-model="res.client_country" name="client_country"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 mb-3">
            <div class="form-group">
              <label>Detalles </label>
              <textarea rows="3" class="form-control" v-model="res.client_details" placeholder="Detalles" name="client_details"></textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-sm-12 col-xs-12 mb-3">
            <div class="form-group">
              <label>Identificador #1 </label>
              <input class="form-control" type="text" v-model="res.business_reference2" placeholder="Identificador" name="business_reference2"/>
            </div>
          </div>
          <div class="col-md-4 col-sm-12 col-xs-12 mb-3">
            <div class="form-group">
              <label>Identificador #2 </label>
              <input class="form-control" type="text" v-model="res.business_reference3" placeholder="Identificador" name="business_reference3"/>
            </div>
          </div>
        </div>
        <div class="row" v-if="isAdmin" >
          <div class="col-md-4 col-sm-12 col-xs-12 mb-3">
            <div class="form-group">
              <label class="required">Agencia </label>
              <select2 :disabled="false" :options="lists.agencies" placeholder=" - Agencia - " v-model="res.agency" name="entity_agency"/>
            </div>
          </div>
        </div>
        <div class="form-footer">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12 mb-3">
              <div class="btn-list">
                <b class="btn btn-danger ml-auto" v-if="canCancelAction" @click="reservation('release')">Cancelar</b>
                <b class="btn btn-primary" :class="{disabled: !isReservationReady}" @click="reservation('confirm')">Confirmar</b>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="alert alert-danger alert-dismissible fade show mt-3" role="alert" v-show="errors.length > 0">
        <ul>
          <li v-for="error in errors">{{ error }}</li>
        </ul>
        <button type="button" class="close" @click="errors = []">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

    </div>
  </div>
</template>

<script type="text/javascript">

// <%# Retorna datos por defecto %>
function getDefaultData (data) {
  if (data == 'res') {
    return {
      destination: '', dealer: '', entity_service: '', entity_contract: '', from_date: null, to_date: null,
      pickup_office: '', dropoff_office: '', entity_tariff: '', entity_modality: '', client_flight: '',
      client_name: '', client_lastname: '', client_email: '', client_passport: '', client_country: '',
      client_details: '', business_reference2: '', business_reference3: '', is_available: false, id: null,
      drivers: '', supplement_insurance: '', agency: '', vip: ''
    };
  }
  return {};
}
export default {
  // Vue.component('reservation-form', {
  // template: '#reservation-form-template',
  props: ['inprogress'],
  data: function(){
    return {
      // <%# Datos de la reserva %>
      res: getDefaultData('res'),
      // <%# Listados de los select %>
      lists: {},
      // <%# Reglas de negocio, Ej min, max de fechas %>
      rules: {},
      // <%# Listado de errores a mostrar %>
      errors: [],
      // <%# Define si se esta ejecutando una solicitud ajax %>
      locked: false,
      // <%# Contiene los datos de la reserva a mostrar en el voucher %>
      voucher: null,
      // <%# Opciones genrales como urls %>
      options: {
        reservationUrl: '/agency/reservation'
      },
      showContent: true
    }
  },
  computed: {
    // <%# El formulario de booking esta listo para consultar disponibilidad %>
    isBookinInfoReady: function() {
      var vm = this;
      return vm.isPresent(vm.res.dealer)
          && vm.isPresent(vm.res.entity_service)
          && vm.isPresent(vm.res.entity_contract)
          && vm.isPresent(vm.res.from_date)
          && vm.isPresent(vm.res.to_date)
          && vm.isPresent(vm.res.pickup_office)
          && vm.isPresent(vm.res.dropoff_office)
          && !vm.locked
          ;
    },

    // <%# Los formularios estan listos para confirmar reserva %>
    isReservationReady: function() {
      var vm = this;
      const regex = new RegExp("^[a-zA-Z0-9]*$");
      let matches = regex.test(vm.res.client_passport)
      return vm.isBookinInfoReady
          && vm.isPresent(vm.res.is_available)
          && vm.isPresent(vm.res.client_flight)
          && vm.isPresent(vm.res.client_name)
          && vm.isPresent(vm.res.client_lastname)
          //&& vm.isPresent(vm.res.client_passport)
          && matches == true
          // && vm.isPresent(vm.res.client_email)
          ;
    },

    // <%#  %>
    canCancelAction: function() { return true; },

    isAdmin: function (){
      return this.lists.admin == '1' || this.lists.admin == 1;
    },

    // <%# Estas funciones basicamente definen el valor disabled=true|false de cada componente (select, datepicker) %>

    disableDealer: function() { return !this.isEnabled(this.res.destination); },

    disableEntityService: function() { return !this.isEnabled(this.res.entity_contract); },

    disableEntityContract: function() { return !this.isEnabled(this.res.dealer); },

    //disableEntityContract: function() { return !this.isEnabled(this.res.entity_service); },

    disableFromDate: function() { return !this.isEnabled(this.res.entity_service); },

    disableToDate: function() { return !this.isEnabled(this.res.from_date); },

    disablePickupOffice: function() { return !this.isEnabled(this.res.from_date); },

    disableDropoffOffice: function() { return !this.isEnabled(this.res.pickup_office); },

    disableEntityTariff: function() { return !this.isEnabled(this.res.entity_contract); },

    disableEntityModality: function() { return !this.isEnabled(this.res.entity_tariff); },

    disableVip: function() { return !this.isEnabled(this.res.entity_tariff); },

    disableCountry: function() { return this.isBlank(this.lists.countries) || this.lists.countries.length == 0 || this.locked; }

  },

  watch: {
    voucher: function(value) {
      var vm = this;
      if (vm.isPresent(value)) {
        vm.voucher = null;
        vm.locked = true;
        vm.res = getDefaultData('res');
        vm.locked = false;
        this.reservation('init');
      }
    },

    // <%# Si comienza una accion entonces recargar formulario desde el servidor %>
    inprogress: function(value) {
      if (value == true) {
        this.reservation('init');
      }
    }
  },

  methods: {
    // <%# Encargado del intercambio de informacion con el servidor %>
    reservation: function(form_action) {
      var vm = this;
      //console.log(vm.$children[10].$el.children[0].value)
      if (vm.locked) {
        return;
      }

      var url = this.options.reservationUrl;
      var data = $.extend(true, {}, this.res);

      if(form_action == 'init') {
        data['form_action'] = 'init'
      }

      if(this.isBookinInfoReady && form_action == 'booking'){
        data['form_action'] = 'booking';
      }

      if(this.isReservationReady && form_action == 'confirm') {
        data['form_action'] = 'confirm';
      }

      if(form_action == 'release') {
        data['form_action'] = 'release';
      }

      data['client_email']= vm.$children[13].$el.children[0].value;

      vm.locked = true;
      this.httpPost(url, data).done(function(response){
        if(vm.isPresent(response.lists)) {
          vm.lists = response.lists;
        }

        if(vm.isPresent(response.rules)) {
          vm.rules = response.rules;
        }

        if (vm.isPresent(response.res)) {
          vm.res.dealer = response.res.dealer || '';
          vm.res.entity_service = response.res.entity_service || '';
          vm.res.destination = response.res.destination || '';
          vm.res.entity_contract = response.res.entity_contract || '';
          vm.res.from_date = response.res.from_date || null;
          vm.res.to_date = response.res.to_date || null;
          vm.res.pickup_office = response.res.pickup_office || '';
          vm.res.dropoff_office = response.res.dropoff_office || '';
          vm.res.entity_tariff = response.res.entity_tariff || '';
          vm.res.entity_modality = response.res.entity_modality || '';
          vm.res.drivers = response.res.drivers || '';
          vm.res.supplement_insurance = response.res.supplement_insurance || '';
          vm.res.vip = response.res.vip || '';

          vm.res.client_flight = response.res.client_flight || '';
          vm.res.client_name = response.res.client_name || '';
          vm.res.client_lastname = response.res.client_lastname || '';
          vm.res.client_email = response.res.client_email || '';
          vm.res.client_passport = response.res.client_passport || '';
          vm.res.client_country = response.res.client_country || '';
          vm.res.client_details = response.res.client_details || '';
          vm.res.business_reference2 = response.res.business_reference2 || '';
          vm.res.business_reference3 = response.res.business_reference3 || '';

          vm.res.is_available = (response.res.is_available === true);

          vm.res.id = response.res.id;
        }

        if (vm.isPresent(response.errors)) {
          vm.errors = response.errors;
        }


        if (vm.isBlank(vm.res.id) && vm.inprogress) {
          vm.$emit('inprogress', false);
        }

        if (vm.isPresent(response.voucher)) {
          vm.voucher = response.voucher;
          vm.$emit('confirmed', response.voucher);
          vm.res = getDefaultData('res');
        }

        if(form_action == 'release') {
          vm.$emit('released', null);
        }

      }).fail(function(){
        vm.errors = ['Ha ocurrido un error al intentar acceder al servidor.'];
      }).always(function() {
        vm.$nextTick(function () {
          vm.locked = false;
        });
      });
    },

    // <%# Funcion ajax, realiza un consulta via post de forma asincrona y retorna un objeto de tipo promise %>
    httpPost: function(url, data) {

      return $.ajax({
        type: 'POST',
        url: url,
        data: data
      });
    },

    // <%# true si isPresent y no se esta haciendo un request al servidor %>
    isEnabled: function(data) { return this.isPresent(data) && !this.locked; }
  },

  // <%# Constructor se ejecuta al cargar la pagina %>
  mounted: function() {
    this.reservation('init');
  }
}
</script>


<style>
#reservation-form {
  display: inline-block;
  width: 100%;
}

#reservation-form hr {
  margin-top: 0px;
}

#reservation-form .action-btn {
  text-align: right;
}

#reservation-form .action-btn .btn:last-child {
  margin-right: 0px;
}

#reservation-form .form-group.action-btn {
  margin-top: 5px;
}

#reservation-form label {
  font-weight: bold;
}

#reservation-form label.required:after {
  content: '*';
  color: red;
}

#reservation-form .bs-callout-danger {
  margin: 0;
  padding: 15px 30px 15px 15px;
  border-left: 5px solid #dFb5b4;
  background-color: #fcf2f2;
  list-style: none;
}
</style>
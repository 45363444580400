import { Controller } from "stimulus"
import $ from 'jquery';

require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")
import reservationContract from "select2"
require('block-ui/jquery.blockUI')
export default class extends Controller {
    connect() {
        $('#cur_item_modality').select2({
            theme: "bootstrap",
            language: "es"
        });
        $("#cur_item_modality").on('select2:select', function () {
            let event = new Event('change', {bubbles: true}) // fire a native event
            this.dispatchEvent(event);
        });
    }

    updateModality() {
        let url = this.data.get('url');
        $.blockUI({message: false});
        $.ajax({
            type: "POST",
            url: url,
            data: $('#book_to_reservation').serialize(),
            success: (data) => {
                $.unblockUI();
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $.unblockUI();
                if (jqXHR.getResponseHeader('REQUIRES_AUTH') === '1') {
                    alert('Su session expiró, por favor acceda nuevamente');
                    window.location = jqXHR.getResponseHeader('ROOT_URL');
                } else {
                    let message = "";
                    if (jqXHR.status === 0) {
                        message = 'Sin conexión: Verifique su conexión de red.';
                    } else if (jqXHR.status == 400) {
                        message = ' Existen problemas en el request';
                    } else if (jqXHR.status == 401) {
                        message = 'Autentiquece nuevamente.';
                    } else if (jqXHR.status == 403) {
                        message = "Autentiquece nuevamente.";
                    } else if (jqXHR.status == 404) {
                        message = 'Página no encontrada [404]';
                    } else if (jqXHR.status == 500) {
                        message = 'Ha ocurrido un error [500] que fue notificado al equipo de soporte, intene nuevamente en unos minutos.';
                    } else if (jqXHR.status == 503) {
                        message = "Service unavailable.";
                    } else if (textStatus === 'parsererror') {
                        message = 'Ha ocurrido un error de interno [JSON] que fue notificado al equipo de soporte, intene nuevamente en unos minutos';
                    } else if (textStatus === 'timeout') {
                        message = 'Time out, tiempo de espera superado, inicie nuevmente, verifique su conexión de red.';
                    } else if (textStatus === 'abort') {
                        message = 'Request abortado.';
                    } else {
                        message = 'Error desconocido: ' + jqXHR.responseText;
                    }
                    // responseText
                    $.ajax({
                        async: true,
                        evalScripts: true,
                        type: "POST",
                        url: "/mail_error?errorStatus=" + jqXHR.status + "&clientMessage=" + message + "&errorThrown=" + errorThrown + "&url=" + this.url,
                        data: {error: jqXHR.responseText}
                    });
                    alert(message);
                }
                alert(message);
            }
        })
    }
}
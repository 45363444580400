<template>
<!--    <datetime  class="form-control  datetimepicker-input" :title="placeholder" :placeholder="placeholder" :id="id" autocomplete="off" data-toggle="datetimepicker" :data-target="target" ></datetime>-->
<!--    <datetime type="datetime" v-model="datetime" class="form-control" ></datetime>-->
    <input type='text' class="form-control  datetimepicker-input" :title="placeholder" :placeholder="placeholder" :id="id" autocomplete="off" data-toggle="datetimepicker" :data-target="target"/>
<!--  assessment-date-keypress Quita el error pero sale doble el componente-->
</template>

<script>
    // Vue.component('datetimepicker', {
    export default {
        props: ['value', 'disabled', 'rules', 'placeholder', 'format', "id", "target"],
        data: function(){
            return {
                datetime: null
            };
        },
        computed: {
            isDisabled: function() {
                var vm = this;
                return vm.disabled !== false;
            }
        },
        // template: '#bootstrapdatetimepicker-template',
        mounted: function () {
            var vm = this;
            //console.log(vm)
            //initialize datepicker with some options
            var datepicker_options = {
                locale: 'es',
                format: vm.format,
                //format: 'DD/MM/YY',
                showClear: true,
                useCurrent: false,
                sideBySide: true,
                stepping: 15,
                // sideBySide: true,
                // debug: true,
                icons: {
                    time: "fa fa-clock-o",
                    date: "fa fa-calendar",
                    up: "fa fa-arrow-up",
                    down: "fa fa-arrow-down",
                    previous: 'fa fa-arrow-left',
                    next: 'fa fa-arrow-right',
                    today: 'fa fa-crosshairs',
                    clear: 'fa fa-trash',
                    close: 'fa fa-remove'
                },
                tooltips:{
                    today:"Hoy",
                    clear:"Limpiar selección",
                    close:"Cerrar",
                    selectMonth:"Selecionar Mes",
                    prevMonth:"Mes Anterior",
                    nextMonth:"Mes Siguiente",
                    selectYear:"Selecionar Año",
                    prevYear:"Año Anterior",
                    nextYear:"Año Siguiente",
                    selectDecade:"Seleccionar Década",
                    prevDecade:"Década Anterior",
                    nextDecade:"Década Siguiente",
                    prevCentury:"Siglo Anterior",
                    nextCentury:"Siglo Siguiente",
                    pickHour:"Seleccionar Hora",
                    incrementHour:"Incrementar Hora",
                    decrementHour:"Decrementar Hora",
                    pickMinute: 'Seleccionar Minuto',
                    incrementMinute: 'Incrementar Minuto',
                    decrementMinute: 'Decrementar Minuto',
                    togglePeriod:"Alternar Período",
                    selectTime:"Selecionar Tiempo"}
            };

            $(vm.$el).datetimepicker(datepicker_options);
            //when a user changes the date, update the view model
            $(vm.$el).on('change.datetimepicker', function (event) {
                vm.datetime = vm.$el.value;
            });

            $(vm.$el).on('hide.datetimepicker', function() {
                var datetime = vm.datetime == '' ? null : vm.datetime;

                if(datetime != vm.value) {
                    vm.$emit('input', datetime);
                }
            });

            this.setDisabledStatus();
        },

        destroyed: function () {
            var vm = this;
            var picker = $(vm.$el).data("datetimepicker");
            if (picker) {
                picker.destroy();
            }
        },

        watch: {
            value: function (value) {
                var vm = this;
                if(vm.datetime != value) {
                    var picker = $(vm.$el).data("datetimepicker");
                    //when the view model is updated, update the widget
                    if (picker) {
                        picker.date(value);
                        vm.datetime = value;
                        vm.$el.value = value;
                    }
                }
            },

            isDisabled: function() {
                this.setDisabledStatus();
            },

            rules: function(rules) {
                var picker = $(this.$el).data("datetimepicker");
                var min = (rules != undefined && rules.min != undefined && moment(rules.min, this.format).isValid()) ? rules.min : false;
                var max = (rules != undefined && rules.max != undefined && moment(rules.max, this.format)) ? rules.max : false;
                picker.maxDate(false);
                picker.minDate(min);
                picker.maxDate(max);

                var disabled_dates = (rules != undefined && rules.disabled_dates instanceof Array) ? rules.disabled_dates : [];
                picker.disabledDates(disabled_dates);
            }
        },

        methods: {
            setDisabledStatus: function(){
                var vm = this;
                var picker = $(vm.$el).data("datetimepicker");
                if (vm.isDisabled) {
                    picker.disable();
                }
                else {
                    picker.enable();
                }
            }
        }
    }
</script>

<style>

/*.bootstrap-datetimepicker-widget table td, .bootstrap-datetimepicker-widget table th {*/
/*    text-align: center !important;*/
/*    padding: inherit;*/
/*    border-top: none;*/
/*}*/

/*.bootstrap-datetimepicker-widget.dropdown-menu.bottom:after {*/
/*    right: inherit;*/
/*}*/

/*.bootstrap-datetimepicker-widget.dropdown-menu.usetwentyfour.top:after {*/
/*    border-bottom: inherit;*/
/*    right: inherit;*/
/*    top: inherit;*/
/*}*/

/*.bootstrap-datetimepicker-widget table td.disabled, .bootstrap-datetimepicker-widget table td.disabled:hover {*/
/*    background: #eee;*/
/*    border-radius: 0;*/
/*}*/

/*.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:before {*/
/*    right: 9px;*/
/*}*/
</style>